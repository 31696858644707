import React from "react";
import { DataModel, core, } from "@essenza/react";

export function EInvoiceModel() {
    DataModel.call(this);
}

core.prototypeOf(DataModel, EInvoiceModel, {
    etype: "einvoice",

    list(state) {
        return this.ExecuteQuery("einv_list", {state});
    },

    detail(state) {
        return this.ExecuteQuery("einv_detail", state);
    },

    export(state) {
        return this.ExecuteApi("einv_export", state);
    },
});