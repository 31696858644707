import { DataModel, core, } from "@essenza/react";

export function ReportModel() {
    DataModel.call(this);
}

core.prototypeOf(DataModel, ReportModel, {
    etype: "report",

    all() {
        return this.ExecuteQuery("report_all");
    },

    report(interval){
        return this.ExecuteQuery("room_report", interval.toISODate());
    },

    send(canvas) {
        let img = canvas.toDataURL("image/png");
        return this.ExecuteApi("send_report", { imageData: img.replace('data:image/png;base64,', '') }, { excludeParams: true });
    }
});