import { useVista, Vista, core, ViewModel } from "@essenza/react";
import * as yup from 'yup';
import { Uiux } from "../widget/uiux";

export function LayoutVista() {
    const vm = useVista(LayoutVVM);
    return (
        <Vista>
            <div className="mx-auto h-full" style={{width: "100%"}}>
                {/* INSERIRE QUI SOTTO I TAG DA TESTARE */}
                <Uiux />
            </div>
        </Vista>
    )
}

export function LayoutVVM() {
    ViewModel.call(this);
}

core.prototypeOf(ViewModel, LayoutVVM, {
    intent: { //swipe or override
        GO_HOME: ({ context }) => {
            context.navigate("home");
        },
    }
});