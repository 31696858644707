import React from "react";
import { DataModel, core, } from "@essenza/react";

export function SuggestionModel() {
    DataModel.call(this);


}

core.prototypeOf(DataModel, SuggestionModel, {
    etype: "suggestion",

    list(date, id){
        return this.ExecuteQuery("sug_list", {date, id});
    },
});