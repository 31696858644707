import { DataModel, core, } from "@essenza/react";

export function PropertyModel() {
    DataModel.call(this);
}

core.prototypeOf(DataModel, PropertyModel, {
    etype: "property",
    
    getAll() {
        return this.ExecuteQuery("properties");
    },

    getManaged(){
        return this.ExecuteApi("hs_managed_props");
    }   
});