import { useVista, Vista, ViewModel, core, useModel, UserModel, UserVM, RULES } from "@essenza/react";
import { Login } from "../../widget/profile/login";
import { Logo } from "../../layout/logo";
import LoginScreen from '../../assets/img/log.jpg';
import * as yup from 'yup';
import { Button } from "antd";
import { Recover } from "../../widget/profile/recover";

export function LoginVista() {
    const vm = useVista(LoginVVM);
    const [user] = useModel(UserModel);
//flex w-fit h-full  max-w-[1000px] max-h-[610px] mx-auto bg-white rounded-md  / [#cbd9e6] = grigio-blu
    return (
        <Vista>
            <div className="flex items-center w-full h-screen bg-black py-6">
                <div style={{ height: "610px"}} className="flex object-scale-down max-h-full m-auto mx-auto bg-white rounded-2xl shadow-2xl drop-shadow-2xl">
                    {/* <img className="flex-none object-scale-down max-h-full m-auto rounded-l-2xl " src={LoginScreen} alt="Immagine Login" /> */}
                    <div style={{width:"500px", height: "610px"}} className="flex-1 place-content-center items-center object-scale-down max-h-full m-auto rounded-r-2xl">
                        <Logo className="mb-12 max-w-xs block mx-auto" />
                        <div className="w-full max-w-sm h-fit mx-auto ">
                            <h1 className="my-4 text-center">
                                {vm.recover ? "Recupera password!" : ""}
                            </h1>
                            {
                                vm.recover
                                    ? <Recover user={user.newInstance()} rules={vm.rules} es-id="recover" />
                                    : <Login user={user.newInstance()} rules={vm.rules} />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Vista>
    )
}

export function LoginVVM() {
    ViewModel.call(this);
    this.recover = false;
    this.use(UserVM).as("log").listen("GO_RECOVER");
    this.use(UserVM, "recover").as("rec").listen("GO_LOGIN");
}

core.prototypeOf(ViewModel, LoginVVM, {
    get rules() {
        return yup.object({
            email: RULES.email(yup),
            password: RULES.password(yup),
        })
    },
    intent: {
        GO_RECOVER() {
            this.recover = true;
            this.update();
        },
        GO_LOGIN() {
            this.recover = false;
            this.update();
        }
    }
});