import { useState } from "react";

export function Menu({ source, unselected, itemClick, horizonal, lite, bgcolor="bg-[#FAC710]" }) {
    const [index, setIndex] = useState(0);

    const onclick = (i, item) => {
        setIndex(i);
        itemClick && itemClick(i, item);
    }

    const direction = horizonal ? "flex " : "";
    let content = null;

    return lite
        ? (<div className="flex">
            {source && source.map((item, i) => <>
                {i === index && !unselected ?
                    <div className={"flex items-center " + bgcolor + " rounded-xl p-1 min-h-8 " + (i === 0 ? '' : ' ml-2')}>
                        {item.icon ? item.icon : null}
                        <h1 className="font-bold mx-2">{item.label}</h1>
                    </div>
                    :
                    <div onClick={() => onclick(i, item)} className="flex items-center cursor-pointer bg-transparent rounded-xl p-1 ml-2 hover:bg-white  min-h-8">
                        {item.icon ? item.icon : null}
                        <h1 className="font-bold mx-2">{item.label}</h1>
                    </div>
                }
            </>)
            }
        </div>)

        : <div className={direction}>
            {source && source.map((item, i) => <>
                {i === index ?
                    <div className={"bg-dark rounded-xl p-3 min-h-12" + (i === 0 ? '' : ' mt-2')}>
                        <h1 className="font-bold text-white flex-auto mx-2">{item.label}</h1>
                    </div>
                    :
                    <div onClick={() => onclick(i, item)} className="cursor-pointer bg-white rounded-xl p-3 mt-2 hover:bg-slate-200  min-h-12">
                        <h1 className="font-bold flex-auto mx-2">{item.label}</h1>
                    </div>
                }
            </>)
            }
        </div>
}