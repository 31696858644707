import React from "react";
import { useWidget, Widget, core, useForm, Form, FormItem, FormVM, useValue } from "@essenza/react";
import { DatePicker, Input, Radio, Select, } from "antd";
import { NumericFormat } from 'react-number-format';
import { PlaceModel } from "../../data/place";
import { Loader } from "../../component/loader";
import * as yup from 'yup';
import dayjs from 'dayjs';

const { TextArea } = Input;

export function Contactcard({ source, parent, group }) {
    const vm = useWidget(ContactcardVM);

    vm.formatGroup(group, source);

    if (parent) parent.vm = vm;

    const form = useForm(source, { rules: vm.$rules }); //, formatter: vm.$formatter
    const countries = useValue("countries");
    const cities = useValue("cities");

    if (!countries) {
        const model = new PlaceModel();
        Promise.all([
            model.createSource("countries", m => m.countryList(), []),
            model.createSource("cities", m => m.cityList(), []),
        ]).then(() => vm.update());
        return <Loader className="mt-12" />;
    }
    const onselect = (value, option) => {
        console.log('radio checked', value, option);
        source.$iso = value;
        source.$country = option.label;
        vm.update();
        //setValue(e.target.value);
    };
    const onchange = (e) => {
        console.log('radio checked', e.target.value);
        source.$company = e.target.value;
        vm.update();
        //setValue(e.target.value);
    };
    return (<Widget>
        <Form form={form} layout="vertical" className="flex flex-col gap-3">
            <FormItem className="flex-none" name="itype">
                <Radio.Group >
                    <Radio value={0}>Venditore/Proprietario</Radio>
                    <Radio value={1}>Acquirente</Radio>
                </Radio.Group>
            </FormItem>

            {
                group.invoice > 0 && <InvoiceGruop />
            }

            <h1 className="bg-black text-white w-full rounded-xl text-lg font-bold p-2 ">Dati Anagrafici</h1>
            {/* <FormItem label="Soggetto" name="company">
                <Radio.Group onChange={onchange} >
                    <Radio value={false}>Privato</Radio>
                    <Radio value={true}>Azienda</Radio>
                </Radio.Group>
            </FormItem> */}
            {
                group.company
                    ? <div className="flex gap-2">
                        <FormItem className="flex-auto" label="Nome azienda" name="businessname">
                            <Input></Input>
                        </FormItem>
                        <FormItem label="Partita iva" name="vatnumber">
                            <Input></Input>
                        </FormItem>
                        <FormItem label="Rappresentante Legale" name="legal">
                            <Input></Input>
                        </FormItem>
                    </div>
                    : <div className="flex gap-2">
                        <FormItem className="flex-1" label="Nome" name="firstname">
                            <Input></Input>
                        </FormItem>
                        <FormItem className="flex-1" label="Cognome" name="lastname">
                            <Input></Input>
                        </FormItem>
                        <FormItem className="w-64" label="Genere" name="gender">
                            <Select className="h-11" options={[{ label: "M", value: "M" }, { label: "F", value: "F" }]} variant="filled" />
                        </FormItem>
                        <FormItem className="basis-1/5" label="Codice fiscale" name="cf">
                            <Input></Input>
                        </FormItem>
                    </div>
            }

            {
                group.birth > 0 && <BirthGruop />
            }

            {
                group.identity > 0 && <DocGruop />
            }

            <div className="flex gap-2">
                <FormItem className="flex-auto" label="Email" name="email">
                    <Input>
                    </Input>
                </FormItem>
                <FormItem className="flex-none" label="Telefono" name="phone">
                    <Input>
                    </Input>
                </FormItem>
                <FormItem className="flex-auto" label="Pec" name="pec">
                    <Input>
                    </Input>
                </FormItem>
                <FormItem className="flex-none" label="Codice destinatario" name="sdi">
                    <Input>
                    </Input>
                </FormItem>
            </div>
            <FormItem label="Indirizzo" name="address">
                <Input></Input>
            </FormItem>
            <div className="flex gap-2">
                <FormItem className="w-64" label="Paese" name="iso">
                    <Select onSelect={onselect} className="h-11" options={countries} variant="filled" />
                </FormItem>
                <FormItem label="Codice postale" name="postcode">
                    <Input></Input>
                </FormItem>
                <FormItem className="flex-auto" label="Citta" name="city">
                    <Input></Input>
                </FormItem>
                {
                    source?.iso === "IT" &&
                    <FormItem className="w-24" label="Provincia" name="area">
                        <Select className="h-11" options={cities} variant="filled" />
                    </FormItem>
                }
            </div>
        </Form>
    </Widget>
    )
}
  
export function ContactcardVM() {
    FormVM.call(this);
    this.state = {};

    /*this.$formatter = {
        ddoc: (v) => v ? dayjs(v, 'YYYY-MM-DD') : null
    }*/

    this.$rules = yup.object({
        email: yup.string().email("Formato email non corretto").nullable(),
        pec: yup.string().email("Formato email non corretto").nullable(),
        cf: yup.string().nullable().when('iso', {
            is: 'IT',
            then: yup.string().required("Richiesto *").nullable(),
            otherwise: yup.string().nullable(),
        }),
        firstname: yup.string().required("Richiesto *").nullable(),
        lastname: yup.string().required("Richiesto *").nullable(),
        vatnumber: yup.string().required("Richiesto *").nullable(),
        businessname: yup.string().required("Richiesto *").nullable(),
        postcode: yup.string().when('iso', {
            is: 'IT',
            then: yup.string().required("Richiesto *").nullable().length(5, "Richieste 5 cifre"),
            otherwise: yup.string().required("Richiesto *").nullable(),
        }),
        city: yup.string().required("Richiesto *").nullable(),
        area: yup.string().required("Richiesto *").nullable(),
        iso: yup.string().required("Richiesto *").nullable(),
        address: yup.string().required("Richiesto *").nullable(),

        birth_date: yup.string().required("Richiesto *").nullable(),
        birth_place: yup.string().required("Richiesto *").nullable(),
        citizenship: yup.string().required("Richiesto *").nullable(),

        idoc: yup.string().required("Richiesto *").nullable(),
        ndoc: yup.string().required("Richiesto *").nullable(),
        doc_issue: yup.string().required("Richiesto *").nullable(),
        doc_date: yup.string().required("Richiesto *").nullable(),
        doc_expiry: yup.string().required("Richiesto *").nullable(),

        amount: yup.string().required("Richiesto *").nullable(),
    });
}

core.prototypeOf(FormVM, ContactcardVM, {
    formatGroup(group, data) {
        if (data) {
            group.company = data.company === null ? false : data.company;
        }
    }
});

function BirthGruop() {
    return (
        <div className="flex gap-2">
            <FormItem className="flex-none" label="Data di nascita" name="birth_date">
                {/* <DatePicker size="small" format={{ format: 'DD-MM-YYYY', type: 'mask', }} /> */}
                <Input></Input>
            </FormItem>
            <FormItem className="flex-auto" label="Luogo di nascita" name="birth_place">
                <Input></Input>
            </FormItem>
            <FormItem className="flex-auto" label="Cittadinanza" name="citizenship">
                <Input></Input>
            </FormItem>
        </div>
    )
}

function DocGruop() {
    return (
        <div className="flex gap-2">
            <FormItem className="flex-auto" label="Tipo documento" name="idoc">
                <Select onSelect={onselect} className="h-11" options={[{ label: "Carta Identità", value: "CI" },
                { label: "Patente di Guida", value: "PG" },
                { label: "Passaporto", value: "PA" },
                { label: "Patente Nautica", value: "PN" },
                { label: "Porto d'armi", value: "PD" },
                { label: "Libretto Pensione", value: "LP" },
                { label: "Patentino abilit. impianti termici", value: "PT" },
                ]} variant="filled" />
            </FormItem>
            <FormItem className="flex-auto" label="Numero documento" name="ndoc">
                <Input></Input>
            </FormItem>
            <FormItem className="flex-auto" label="Rilasciato da" name="doc_issue">
                <Input></Input>
            </FormItem>
            <FormItem className="flex-none" label="Rilasciato il" name="doc_date">
            <Input></Input>
                </FormItem>
            <FormItem className="flex-none" label="Scadenza" name="doc_expiry">
                <Input></Input>
            </FormItem>
        </div>
    )
}

function InvoiceGruop() {
    return (
        <div>
            <FormItem className="flex-none" label="Importo netto fattura" name="amount" trigger="onValueChange" getValueFromEvent={v => v.floatValue} >
                <NumericFormat className="pl-7" customInput={Input} prefix="€" decimalSeparator="," thousandSeparator="." fixedDecimalScale />
            </FormItem>
            <FormItem className="flex-1" label="Note fattura" name="note">
                <TextArea rows={2}></TextArea>
            </FormItem>
        </div>
    )
}
