import { useVista, Vista, core, ViewModel } from "@essenza/react";
import { RoomReport } from "../widget/room/roomreport";
import { Menu } from "../component/menu";
import { FinancialReport } from "../widget/financial/report";

export function ReportVista() {
    const vm = useVista(ReportVVM);
    let content = null;
    
    switch (vm.selected.value) {
        case 0: content = <RoomReport />; break;
        case 2: content = <FinancialReport />; break;
        default: content = <h1 className="text-xl mt-32">In costruzione...</h1>
    }

    return (
        <Vista>
            <div className="flex gap-4 p-3">
                <div className="p-3 bg-white rounded-2xl min-w-64">
                    <Menu source={vm.menuitem} itemClick={(_, item) => vm.navigate(item)} />
                </div>
                <div className="w-full">
                    {content}
                </div>
            </div>
        </Vista>
    )
}

export function ReportVVM() {
    ViewModel.call(this);
    this.authorized = this.context.role.authorize("ADMIN,USER");
    this.menuitem =  [{ label: 'Report Proprietà', value: 0 },];
    this.authorized && this.menuitem.push({ label: 'Report Vendita e Consulenza', value: 1 },
        { label: 'Dashbord Finanza', value: 2 },
        { label: 'Channel', value: 3 })

    this.selected = this.menuitem[0];
}

core.prototypeOf(ViewModel, ReportVVM, {
    navigate(item) {
        this.selected = item;
        this.update();
    },
    intent: {
        SELECT({ data, token }) {
        },
    }
});