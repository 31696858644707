import React  from "react";
import  { DataModel, core,  } from "@essenza/react";

export function LandlordModel(){
DataModel.call(this);


}

core.prototypeOf(DataModel, LandlordModel, {
            etype: "landlord",
        });