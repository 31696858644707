import { BsArrowRightCircleFill } from "react-icons/bs";
import { IoIosAlert } from "react-icons/io";
import { RiSearch2Line } from "react-icons/ri";
import { TbHelpSquareRoundedFilled } from "react-icons/tb";
import { IoSync } from "react-icons/io5";
import { FaUserFriends } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import { TbMailFast } from "react-icons/tb";
import { useWidget, Widget, useForm, Form, FormItem, $Array, ViewModel, core, useFilter } from "@essenza/react";
import { Input, Button, Tooltip, InputNumber, Select, Radio, Popconfirm, Checkbox, Tag } from 'antd';
import { useMemo, useState } from "react";
import { LandlordVista } from "../../vista/landlord";

export function FormUI() {
    return <>
        <div className=" h-full flex p-4 w-2/3 mx-auto">
            <div className="flex-auto bg-slate-100  rounded-l-3xl ">
                WELCOME
            </div>
            <div className="flex-auto rounded-r-3xl bg-black opacity-85">
                <h1 className="text-yellow-600">WELCOME</h1>
            </div>
        </div>
    </>
}

export function PropertyUI() {
    const rooms = useMemo(() => [{ value: 1, label: "Appartamento Bilocale" }, { value: 2, label: "Trilocale A" }, { value: 3, label: "Trilocale B" }, { value: 4, label: "Trilocale C" }, { value: 5, label: "Trilocale D" }, { value: 6, label: "Trilocale E" }], []);

    const list = useMemo(() => [
        {
            title: "Casa Con Vista Uzzano Castello", kind: "Appartamento", warning: 0,
            contacts: [
                { name: "Jacopo", surname: "Tartaglia", residence: "Via roma, 200 Firenze 50100", cf: "ABCDEF20Z00A000B" },
                { name: "Chiara", surname: "Cesca", residence: "Via roma, 200 Firenze 50100", cf: "ABCDEF20Z00A000B" }
            ],
            //rooms: []
            //rooms: [{ key: 1, label: "Appartamento Bilocale" }, { key: 2, label: "Trilocale A" }, { key: 3, label: "Trilocale B" }, { key: 4, label: "Trilocale C" }, { key: 5, label: "Trilocale D" }, { key: 6, label: "Trilocale E" }]
        },
        { title: "Casa con vista Matraia", kind: "Appartamento" },
        { title: "Bilocale nel cuore della città di Pescia", kind: "Appartamento", warning: true },
        { title: "Torre del Lago- Casa al Mare", kind: "Appartamento", warning: true },
        { title: "Viareggio - Appartamento affitti brevi via pisano 61", kind: "Appartamento" },
    ], []);

    const [index, setIndex] = useState(0);
    const [apt, setApt] = useState(0);
    const [inv, setInv] = useState(false);

    const property = list ? list[index] : { title: "Nessun immobile selezionato" };
    const apartment = property.rooms ? property.rooms[apt] : null;

    const vm = useWidget(PropertyVM);
    const form = useForm(apartment);
    const [filter, data] = useFilter(list, {
        warning: i => i.warning,
        title: (i, v) => i.title.toLowerCase().includes(v.title.toLowerCase())
    })

    const onselect = (v, o) => {
        property.rooms = property.rooms ? [...property.rooms, o] : [o];
        //vm.update();
    }

    const ondeselect = (v, o) => {
        $Array.remove(property.rooms, room => room.value === o.value);
        //vm.update();
    }

    const onclear = (v, o) => {
        property.rooms = null;
        //vm.update();
    }

    return <Widget><div ref={vm.container} className="flex items-center gap-3 h-full">

        <div className=" h-full flex flex-col gap-3 p-1 w-1/4 ">
            <div className="flex pl-2 pr-5 items-center">
                <span className="flex-auto font-bold text-lg">Proprietà in Gestione</span>
                <Tooltip title="Tour Guidato" placement="bottom" >
                    <TbHelpSquareRoundedFilled className="text-3xl text-stone-400 min-w-6 cursor-pointer" />
                </Tooltip>
            </div>

            <div className="flex gap-1">
                <Input onChange={e => filter.apply({ title: e.target.value })}
                    prefix={<><span className="rounded-full bg-amber-500 w-2 h-2"></span><Checkbox onChange={e => e.target.checked ? filter.apply({ warning: true }) : filter.unset("warning", true)} /></>}
                    suffix={<RiSearch2Line className="text-3xl " />}
                    placeholder="Ricerca proprietà"
                    className="p-4 flex-auto rounded-full h-12 bg-white shadow-2xl"
                />
            </div>

            <div className="rounded-3xl h-full bg-white shadow-2xl flex flex-col gap-2 p-6">
                {
                    data.map((prop, i) => <>
                        {i === index ?
                            <div className="flex items-center bg-black rounded-3xl p-3 min-h-16">
                                {prop.warning && <span className="rounded-full bg-amber-500 w-2 h-2"></span>}
                                <h1 className="font-bold text-white flex-auto mx-2">{prop.title}</h1>
                                <Tooltip title="Sincronizza dati hubspot" color="gold" placement="right" >
                                    <IoSync className="text-2xl min-w-6 cursor-pointer text-white" />
                                </Tooltip>
                            </div>
                            :
                            <div onClick={() => setIndex(i)} className="flex items-center cursor-pointer bg-white rounded-3xl p-3 hover:bg-slate-200  min-h-16">
                                {prop.warning && <span className="rounded-full bg-amber-500 w-2 h-2"></span>}
                                <h1 className="font-bold flex-auto mx-2">{prop.title}</h1>
                                <BsArrowRightCircleFill className="text-2xl min-w-6 cursor-pointer" />
                            </div>
                        }
                    </>)
                }
            </div>
        </div>

        <div className="h-full  w-3/4 bg-white  shadow-2xl rounded-b-3xl rounded-tr-3xl">
            <div className="bg-black p-2 flex gap-3 w-full h-22 ">
                <div className="ml-2 h-full flex-auto"><span className="font-bold text-white text-xl mr-2">{property.title}</span>
                    {/* <span><Tag color="#404040">HS</Tag></span> */}</div>
                <div>
                    <Tooltip onClick={() => vm.emit("OPEN_CONTACT", property.contacts)} title="Cambia o modifica soggetto fiscale" color="gold" placement="left" >
                        <FiEdit className="text-white text-3xl cursor-pointer mb-2" />
                    </Tooltip>
                    {property.contacts && <Tooltip open={property.warning === 2 ? true : null} title={property.warning === 2 ? "Dati incompleti" : "Dati importati da hubspot"} color="gold" placement="left" >
                        <Tag color="#404040">HS</Tag>
                    </Tooltip>}
                </div>

                {
                    property.contacts
                        ?
                        <div className="cursor-pointer">
                            <span className="font-bold text-white text-xl ">{property.contacts[0].title}</span>
                            <h1 className="font-bold text-neutral-600 text-md">{property.contacts[0].address}</h1>
                            <h1 className="font-bold text-slate-500 text-md">{property.contacts[0].cf}</h1>
                        </div>
                        : <Tooltip open={true} title="Seleziona un soggetto fiscale" color="gold" placement="left" ><FaUserFriends className="text-white text-3xl cursor-pointer" /></Tooltip>
                }
            </div>
            <div className="basis-3/4 flex flex-col px-6 mt-3">
                <div className="flex items-center gap-1">
                    <h1 className="font-bold text-xl flex-auto">Appartamenti</h1>
                    <Checkbox onChange={e => setInv(e.target.checked)} className="font-bold">Fatturazione conto proprietario</Checkbox>
                    {
                        inv && <>
                            <Tooltip title="Invia richiesta proprietario di abilitazione fatturazione Qonto" placement="bottom" ><TbMailFast className="text-3xl cursor-pointer" /></Tooltip>
                            <Tag color="geekblue">Attesa invio Richiciesta</Tag>
                        </>
                    }
                </div>
                <div className="flex items-center  gap-3 mt-2 p-3 bg-[#f4f4fc]  rounded-3xl">
                    <Popconfirm showCancel={false} destroyTooltipOnHide={true} placement="bottomLeft" trigger="click"
                        title={<span className="font-bold flex-auto">Seleziona gli appartamenti da associare</span>} onOpenChange={v => { !v && setApt(0) }}
                        description={<div className="h-60 w-[640px]">
                            <Select className="w-full bg-white" defaultOpen={true}
                                mode="multiple" allowClear optionFilterProp="label" onSelect={onselect} onDeselect={ondeselect} onClear={onclear}
                                options={rooms} defaultValue={property.rooms} />
                        </div>}>
                        <Button onClick={e => setApt(-1)} type="dashed" ghost className="w-16 h-16">+</Button>
                    </Popconfirm>
                    <div className="flex flex-wrap gap-3">
                        {
                            property.rooms && property.rooms.length > 0
                                ? property.rooms.map((room, i) => i === apt ?
                                    <div className="flex items-center border-2 border-black py-1 rounded-md ">
                                        <h1 className="font-bold flex-auto mx-2">{room.label}</h1>
                                    </div>
                                    :
                                    <div onClick={() => setApt(i)} className="flex items-center rounded-xl cursor-pointer p-1 hover:px-0 hover:bg-slate-200 hover:font-bold min-h-12  ">
                                        <h1 className="flex-auto mx-2">{room.label}</h1>
                                    </div>)
                                // <Menu onClick={e => setApt(e.key - 1)} selectedKeys={[apt + 1]} mode="horizontal" items={property.rooms} />
                                : <>
                                    <IoIosAlert className="text-3xl" />
                                    <h1 className="font-bold text-lg text-zinc-800">Nessun appartamento associato</h1>
                                </>
                        }
                    </div>
                </div>
                {
                    apartment &&
                    <div className="flex-auto mt-4 px-6">
                        {/* <h1 className="font-bold text-xl">{apartment.label}</h1> */}
                        <Form form={form} layout='vertical' className="flex flex-col gap-3 mt-4" >
                            <div className="flex gap-3 flex-wrap">
                                <FormItem className="flex-1 border-b-2 border-b-slate-200" label="Tipo Fatturazione" name="kind">
                                    <Select variant="filled" placeholder="Tipo Fatturazione" options={[
                                        { value: 'F', label: <span>Cliente importo completo</span> },
                                        { value: 'C', label: <span>Cliente Solo Commissioni</span> },
                                        { value: 'M', label: <span>Proprietario fine mese</span> },
                                    ]} />
                                </FormItem>
                                <FormItem className="flex-1 border-b-2 border-b-slate-200" label="Commissione" name="commission">
                                    <Input variant="borderless" placeholder="% commissione"></Input>
                                </FormItem>
                                <FormItem className="flex-1 border-b-2 border-b-slate-200" label="Calcolata" name="as">
                                    <Radio.Group >
                                        <Radio value={1}>Su lordo</Radio>
                                        <Radio value={2}>Su netto</Radio>
                                    </Radio.Group>
                                </FormItem>
                            </div>

                            <div className="flex flex-wrap space-x-3 mt-4">
                                <FormItem className="flex-1 border-b-2 border-b-slate-200" label="Costo pulizia per soggiorno" name="clean">
                                    <InputNumber prefix="€" variant="filled" style={{ width: '100%' }} />
                                </FormItem>
                                <FormItem className="flex-1 border-b-2 border-b-slate-200" label="Costo biancheria per ospite" name="linen">
                                    <InputNumber prefix="€" variant="borderless" style={{ width: '100%' }} />
                                </FormItem>
                                <FormItem className="flex-1 border-b-2 border-b-slate-200" label="TAG Qonto" name="qtag">
                                    <Input variant="borderless" placeholder=""></Input>
                                </FormItem>
                            </div>
                            <div>
                                <button className="btn-dark bg-black float-right w-32 rounded-full" onClick={() => vm.emit("LOGIN")}>
                                    Conferma
                                </button>
                            </div>

                        </Form>
                    </div>
                }

            </div>
        </div>
    </div>
    </Widget>
}

export function PropertyVM() {
    ViewModel.call(this);
    this.container = {};
    this.popcontact = false;
    this.poproom = false;
}

core.prototypeOf(ViewModel, PropertyVM, {
    popRoom() {
        this.poproom = !this.poproom;
        this.update();
    },

    popContact() {
        this.poproom = !this.poproom;
        this.update();
    },

    intent: {
        GO_HOME: ({ context }) => {
            context.navigate("home");
        },

        OPEN_CONTACT({ data }) {
            this.context.openModal({
                kind: "confirm",
                content: <LandlordVista source={data} />,
                title: "CONTATTI",
                okText: "CONFERMA",
                cancelText: "ANNULLA",
                centered: true,
                width: 980,
                //onOk: () => printer.print(),
            })
        }
    }
});

//[#8995af] d6ecfa

/* <div className="py-3 px-6"> <Select  variant="borderless" options={[{value: 0, label: "Jacopo Tartaglia"}]} />
               <div className="flex gap-3 p-4 bg-[#f4f4fc] rounded-3xl mt-3">
                   <div className="min-h-16 bg-white p-2 shadow-xl rounded-2xl">
                       <h1 className="font-bold text-xl">Jacopo Tartaglia</h1>
                       <div className="flex items-center gap-2">
                           <FaSquarePhone className="text-xl " />
                           <h1 className="font-bold text-md">339 262485</h1>
                       </div>
                       <div className="flex items-center gap-2">
                           <IoMail className="text-xl " />
                           <h1 className="font-bold text-md">email@email.it</h1>
                       </div>
                   </div>
                   <div className="min-h-16 bg-white p-2 shadow-xl rounded-2xl">
                       <h1 className="font-bold text-xl">Jacopo Tartaglia</h1>
                       <div className="flex items-center gap-2">
                           <FaSquarePhone className="text-xl " />
                           <h1 className="font-bold text-md">339 262485</h1>
                       </div>
                       <div className="flex items-center gap-2">
                           <IoMail className="text-xl " />
                           <h1 className="font-bold text-md">email@email.it</h1>
                       </div>
                   </div>
               </div>
           </div> */
/* <div className="basis-1/4 align-middle flex flex-col">
    <div className="bg-black p-3  w-full h-32">
        <h1 className="font-bold text-white text-xl">Bilocale nel cuore della città di Pescia</h1>
    </div>
    <div className="rounded-b-2xl w-full p-4 flex-auto bg-[#f4f4fc] flex flex-col gap-3">
        <h1 className="font-bold text-xl">Contatti</h1>
        <div className="min-h-16 w-full bg-white p-2 shadow-2xl rounded-2xl">
            <h1 className="font-bold text-xl">Jacopo Tartaglia</h1>
            <div className="flex items-center gap-2">
                <FaSquarePhone className="text-xl " />
                <h1 className="font-bold text-md">339 262485</h1>
            </div>
            <div className="flex items-center gap-2">
                <IoMail className="text-xl " />
                <h1 className="font-bold text-md">email@email.it</h1>
            </div>
        </div>
        <div className="min-h-16 w-full bg-white p-2 shadow-2xl rounded-2xl">
            <h1 className="font-bold text-xl">Jacopo Tartaglia</h1>
            <div className="flex items-center gap-2">
                <FaSquarePhone className="text-xl " />
                <h1 className="font-bold text-md">339 262485</h1>
            </div>
            <div className="flex items-center gap-2">
                <IoMail className="text-xl " />
                <h1 className="font-bold text-md">email@email.it</h1>
            </div>
        </div>
        <h1 className="font-bold text-xl mt-6">Dati Fatturazione</h1>
        <div className="min-h-16 w-full border-2 bg-white border-slate-800 p-2 shadow-2xl rounded-2xl">
            <div className="flex items-center gap-2">
                <GiPositionMarker className="text-xl " />
                <div>
                    <h1 className="font-bold text-md">Indirizzo</h1>
                    <h1 className="font-bold text-slate-500 text-md">Via roma, 200 Firenze</h1>
                </div>
            </div>
            <div className="flex items-center gap-2 mt-6">
                <FaSquarePhone className="text-xl " />
                <div>
                    <h1 className="font-bold text-md">Codice Fiscale</h1>
                    <h1 className="font-bold text-slate-500 text-md">123456789</h1>
                </div>

            </div>
        </div>
    </div>
</div> */