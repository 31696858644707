import React from "react";
import { useWidget, Widget, core, ViewModel, useForm, Form, FormItem, FormVM, } from "@essenza/react";
import { Input, } from "antd";
import { MdOutlinePlace } from "react-icons/md";
import { TiBusinessCard } from "react-icons/ti";
import { IoMailOutline } from "react-icons/io5";
import { HiOutlinePhone } from "react-icons/hi";
import * as yup from 'yup';

export function Landlordcard({ source }) {
	const vm = useWidget(LandlordcardVM);
	const form = useForm(source, { rules: vm.rules });

	return (<Widget>
		<Form form={form} layout="vertical" className="flex flex-col gap-3">
			<FormItem label="Id" name="id">
				<Input>
				</Input>
			</FormItem>
			<FormItem label="Warning" name="warning">
				<Input>
				</Input>
			</FormItem>
			<FormItem label="Surname" name="surname">
				<Input>
				</Input>
			</FormItem>
			<FormItem label="Email" name="email">
				<Input>
				</Input>
			</FormItem>
			<FormItem label="Street" name="street">
				<Input>
				</Input>
			</FormItem>
			<FormItem label="Code" name="code">
				<Input>
				</Input>
			</FormItem>
			<FormItem label="Locality" name="locality">
				<Input>
				</Input>
			</FormItem>
			<FormItem label="City" name="city">
				<Input>
				</Input>
			</FormItem>
			<FormItem label="Cf" name="cf">
				<Input>
				</Input>
			</FormItem>
			<FormItem label="Vatnumber" name="vatnumber">
				<Input>
				</Input>
			</FormItem>
			<FormItem label="Businessname" name="businessname">
				<Input>
				</Input>
			</FormItem>
			<FormItem label="Idhs" name="idhs">
				<Input>
				</Input>
			</FormItem>
			<FormItem label="Phone" name="phone">
				<Input>
				</Input>
			</FormItem>
			<FormItem label="Idagent" name="idagent">
				<Input>
				</Input>
			</FormItem>
			<FormItem label="Lifecyclestage" name="lifecyclestage">
				<Input>
				</Input>
			</FormItem>
			<FormItem label="Domain" name="domain">
				<Input>
				</Input>
			</FormItem>
			<FormItem label="Residence" name="residence">
				<Input>
				</Input>
			</FormItem>
			<FormItem label="Region" name="region">
				<Input>
				</Input>
			</FormItem>
			<FormItem label="Country" name="country">
				<Input>
				</Input>
			</FormItem>
			<FormItem label="Name" name="name">
				<Input>
				</Input>
			</FormItem>
		</Form>
	</Widget>)
}

export function Lordlitecard({ source }) {
	const vm = useWidget(LandlordcardVM);
	//schema = useMemo(() => vm.formatSchema(schema), []);
	const form = useForm(source, vm.schema);

	return (<Widget>
		<Form form={form} layout="vertical" className="flex flex-col gap-3">
			<div className="flex gap-3 flex-wrap">
				<FormItem className="flex-1" label="Cognome" name="lastname">
					<Input variant="filled">
					</Input>
				</FormItem>
				<FormItem className="flex-1" label="Name" name="firstname">
					<Input variant="filled">
					</Input>
				</FormItem>
			</div>
			<FormItem label="Indirizzo" name="residence">
				<Input prefix={<MdOutlinePlace />} variant="filled">
				</Input>
			</FormItem>
			<FormItem label="Codice Fiscale" name="cf">
				<Input className="w-1/2" prefix={<TiBusinessCard />} variant="filled">
				</Input>
			</FormItem>
			<div className="flex gap-3 flex-wrap">
				<FormItem className="flex-auto" label="Email" name="email">
					<Input prefix={<IoMailOutline />} variant="filled">
					</Input>
				</FormItem>
				<FormItem className="w-1/3" label="Telefono" name="phone">
					<Input prefix={<HiOutlinePhone />} variant="filled">
					</Input>
				</FormItem>
			</div>
			<div className="flex gap-3 flex-wrap">
				<FormItem className="flex-auto" label="Azienda" name="businessname">
					<Input variant="filled">
					</Input>
				</FormItem>
				<FormItem className="w-1/3" label="Partita Iva" name="vatnumber">
					<Input variant="filled">
					</Input>
				</FormItem>
			</div>
		</Form>
	</Widget>)
}

export function LandlordcardVM() {
	ViewModel.call(this);
	this.schema = {
		rules: {
			default: yup.object({
				cf: yup.string().required("Richiesto *").nullable(),
				lastname: yup.string().required("Richiesto *").nullable(),
				firstname: yup.string().required("Richiesto *").nullable(),
				residence: yup.string().required("Richiesto *").nullable(),
			})
		}
	}
}

core.prototypeOf(ViewModel, LandlordcardVM, {
	intent: {
		SUBMIT({ data }) {
			console.log(data);
		},
	}
});
