import React, { useMemo, useState } from "react";
import { useWidget, Widget, core, ViewModel, } from "@essenza/react";
import { TbUserEdit } from "react-icons/tb";
import { PiShareNetworkBold } from "react-icons/pi";
import { TbFileEuro } from "react-icons/tb";
import { DateInterval, IT, Nullable } from "../../core/utils";
import { Input, notification } from "antd";
import { Einvoicecard, Einvoicelite } from "../einvoice/einvoicecard";
import { IoEyeOutline } from "react-icons/io5";
import { EInvoiceView } from "./view";
import { CiEdit } from "react-icons/ci";
import { Transactions } from "../../core/transaction";
import { TbAlertCircleFilled } from "react-icons/tb";

const { TextArea } = Input;

export function EnvoicePreview({ data }) {
    const vm = useWidget(EnvoiceVM);
    const invoice = vm.formatInvoice(data);
    const action = vm.getAction(data);
    useMemo(() => vm.note = data.note, [data, vm]);
    const total = invoice.total;
    const kind = { F: "Proprietario FM", O: "Ospite", C: "Commissione", N: "Consulenza" }
    return (<Widget>
        <div className="flex">
            <div className="basis-1/2 p-2 flex gap-2">
                {
                    (data.firstname && data.lastname) || data.businessname
                        ? <div className="font-semiboldbold text-sm">
                            <h1 className="font-bold ">{data.company || data.businessname ? Nullable(data.businessname) : (Nullable(data.firstname) + " " + Nullable(data.lastname))}</h1>
                            <h1>{Nullable(data.address)}</h1>
                            <h1>{Nullable(data.postcode) + " " + Nullable(data.city) + ", " + (data.country || '') + ", " + Nullable(data.iso)}</h1>
                            <h1>{Nullable(data.cf?.toUpperCase())}</h1>
                        </div>
                        : "Dati destinatario mancanti"
                }
                <TbUserEdit onClick={() => vm.emit("EDIT_TAXABLE", data)} className="cursor-pointer text-xl" />
            </div>
            <div className="basis-1/2 p-2">
                <div className="flex items-center gap-2">
                    <PiShareNetworkBold /> <h1 className="flex-auto">{data.channel}</h1>
                    <IoEyeOutline onClick={() => vm.emit("PREVIEW", data)} className="text-xl cursor-pointer" />
                </div>
                <div className="flex items-center gap-2">
                    <TbFileEuro /> <h1 className="flex-auto">Ft. {kind[data.invoicing] + (data.invfor ? " - Ft. ospite al proprietario" : "")}</h1>
                    <CiEdit onClick={() => vm.emit("EDIT_INV", data)} className="cursor-pointer text-xl" />
                </div>
            </div>
        </div>

        <div className="flex flex-col gap-2 font-bold">
            <div className="flex bg-black text-white w-full py-2 px-4 rounded-xl">
                <div className="basis-7/12">Descrizione</div>
                <div className="basis-1/12">Qtà</div>
                <div className="basis-1/12">Imponibile</div>
                <div className="basis-2/12 text-center">Iva</div>
                <div className="basis-1/12">Totale</div>
            </div>
            {
                invoice.items.map(item =>
                    <div className="flex bg-gray-100 w-full py-0 px-4 rounded-xl items-center">
                        <div className="basis-7/12">{item.description}</div>
                        <div className="basis-1/12">{item.qty}</div>
                        <div className="basis-1/12">{item.amount}</div>
                        <div className="basis-2/12 text-center">{item.vat}</div>
                        <div className="basis-1/12">{item.total}</div>
                    </div>
                )
            }
            <div className="flex ">
                <div className="w-72">
                    <div className="flex py-1 px-4 rounded-xl">
                        <div className="basis-1/2">Totale (IVA escl.)</div>
                        <div className="basis-1/2 text-right">{total.amount}</div>
                    </div>
                    <div className="flex  py-1 px-4 rounded-xl">
                        <div className="basis-1/2">Importo totale IVA</div>
                        <div className="basis-1/2 text-right">{total.vat}</div>
                    </div>
                    <div className="flex bg-gray-100  py-1 px-4 rounded-xl">
                        <div className="basis-1/2">Saldo finale</div>
                        <div className="basis-1/2 text-right">{total.value}</div>
                    </div>
                </div>
                <div className="flex-auto text-right">
                    <h1 className="ml-2">{total.esenzione}</h1>
                </div>
            </div>
            <TextArea value={vm.note} onChange={(e) => { vm.note = e.target.value; vm.update() }} onBlur={(e) => vm.emit("NOTE", { item: data, value: e.target.value })} className="w-full mt-1 bg-white" rows={1} placeholder="Aggiungi nota in fattura" />

            {
                data.istate !== 2 &&
                <div className="flex gap-2 justify-around mt-1">
                    <button className="btn-dark bg-dark rounded-full" onClick={() => vm.emit("CHANGE_STATE", action)}>
                        {action.label}
                    </button>
                    {data.istate !== 6 && data.groupid !== 7 &&
                        <button className="btn-ghost rounded-full" onClick={() => vm.emit("CHANGE_STATE", { item: data, state: 6, message: "Attività sospesa" })}>
                            Sospendi
                        </button>
                    }
                    {data.groupid !== 3 &&
                        <button className="btn-ghost rounded-full" onClick={() => vm.emit("CHANGE_STATE", { item: data, state: 7, message: "Attività archiviata" })}>
                            Archivia
                        </button>
                    }
                </div>
            }
            {
                data.warnings && data.groupid !== 5 && <div className="bg-dark text-white text-base rounded-xl pl-4 p-2 mt-2 font-semibold">
                    {
                        data.warnings.map(w =>
                            w ?
                                <div className=" flex gap-2">
                                    <TbAlertCircleFilled className={"text-2xl text-[" + (w.state === 1 ? "#FAC710" : "#1687DE") + "]"} />
                                    <h1>{w.code + ' ' + (w.description || '')}</h1>
                                </div> : null)
                    }
                </div>
            }
        </div>
    </Widget>)
}

export function EnvoiceVM() {
    ViewModel.call(this);
}

core.prototypeOf(ViewModel, EnvoiceVM, {
    formatInvoice(data) {
        let items = [];
        let total = { amount: 0, vat: 0, value: 0, esenzione: "" };
        let checkout = new Date(data.date);
        let item = {
            description: data.commission_title ? <h1>{data.commission_title}</h1> : <>
                <h1>{data.description}</h1>
                <h1>Prenotazione {data.referent}</h1>
                <h1>{data.title + " " + new DateInterval(new Date(data.checkin), checkout).toIntervalString()}</h1>
            </>,
            qty: 1,
        }

        if (data.egroup === 2) {

            if (!data.commission_title)
                item.description = <>
                    <h1>{data.title}</h1>
                    <h1>{data.idescription}</h1>
                </>;

            let amount = data.net;
            let vat = data.commission;

            if (data.company && data.continent === "UE") {
                total.esenzione += "N2.1 - esenzione art.7"
            }
            else if (vat === 0) {
                const net = (amount*100) / 122;
                vat = amount - net;
                amount = net;
            }

            item.amount = IT.currency(amount);
            item.vat = total.esenzione === "" ? IT.currency(vat) : "(N2.1)";
            item.total = IT.currency(amount + vat);

            total.amount = amount;
            total.vat = vat;
        }
        else if (data.egroup === 4) {
            item = null;
            const costs = new Transactions();
            costs.import(data.costs);

            if (costs.extra) {
                total.esenzione += "N1 - esenzione art.15"
                costs.extra.forEach(item => {
                    if(item.enabled){
                        items.push({
                            description: item.label,
                            qty: 1,
                            amount: IT.currency(item.amount),
                            vat: "(N1)", //IT.currency(item.vat_amount), //IT.currency(0)+
                            total: IT.currency(item.amount)
                        });
                        total.amount += item.amount;
                    } 
                })
            }
        }
        else if (data.summary) {
            const checkin = new Date(data.checkin)
            const monthLable = DateInterval.prototype.localMonth(checkin) + " " + checkin.getFullYear();
            const costs = new Transactions();
            costs.import(data.costs);

            if (!data.commission_title)
                item.description = "Gestione " + monthLable;

            let amount = data.commission; //(data.netota * data.commission) / 100;
            amount += costs.commission.amount;

            let vat = 0;

            if (data.company && data.continent === "UE") {
                total.esenzione += "N2.1 - esenzione art.7"
            }
            //Devo aggiungere caso FM proprietario è azienda UE => esenzione
            else {
                vat = ((amount * 22) / 100);
            }

            item.amount = IT.currency(amount);
            item.vat = total.esenzione === "" ? IT.currency(vat) : "(N2.1)";
            item.total = IT.currency(amount + vat);

            total.amount = amount;
            total.vat = vat;

            if (data.cleaning) {
                total.esenzione += "N1 - esenzione art.15"
                let cleaning_amount = data.cleaning + costs.cleaning.amount;//(data.cleaning * data.rooms) + costs.cleaning.amount;
                items.push({
                    description: data.cleaning_title ? data.cleaning_title : "Pulizie " + monthLable,
                    qty: 1,
                    amount: IT.currency(cleaning_amount),
                    vat: "(N1)", //IT.currency(0) +
                    total: IT.currency(cleaning_amount)
                });

                total.amount += cleaning_amount;
            }

            if (data.linen) {
                let linen_amount = data.linen + costs.linen.amount; //(data.linen * data.guests) + costs.linen.amount;
                items.push({
                    description: data.linen_title ? data.linen_title : "Lavanderia " + monthLable,
                    qty: 1,
                    amount: IT.currency(linen_amount),
                    vat: "(N1)", //IT.currency(0)+
                    total: IT.currency(linen_amount)
                });

                total.amount += linen_amount;
            }

            if (costs.cost.amount || costs.utility.amount) {
                items.push({
                    description: "Altre spese " + monthLable,
                    qty: 1,
                    amount: IT.currency(costs.cost.amount + costs.utility.amount),
                    vat: "(N1)", //IT.currency(0)+
                    total: IT.currency(costs.cost.amount + costs.utility.amount)
                });

                total.amount += costs.cost.amount;
            }

            if (costs.item.items) {
                costs.item.items.forEach(item => {
                    items.push({
                        description: item.label,
                        qty: 1,
                        amount: IT.currency(item.amount - item.vat_amount),
                        vat: IT.currency(item.vat_amount), //IT.currency(0)+
                        total: IT.currency(item.amount)
                    });
                    total.amount += item.amount;
                })
            }
        }
        else if (data.invoicing === 'O') {
            let gross = (data.gross_amount || data.gross) + data.extra;
            item.amount = IT.currency(gross);
            item.vat = "(N2.2)"; //IT.currency(0) + 
            item.total = IT.currency(gross);

            total = {
                amount: gross,
                vat: 0,
                value: gross,
                esenzione: "N2.2 - esenzione ex art. 10"
            };
        }
        else if (data.invoicing === 'C' || data.invoicing === 'F') {
            let amount = data.icommission === 1 ? data.netota : (data.netota - data.cleaning - (data.linen * data.guests));
            if (amount < 0) amount = 0;
            amount = (amount * data.commission) / 100;
            let vat = 0;

            if (data.company && data.continent === "UE" && data.invoicing === 'C') {
                total.esenzione += "N2.1 - esenzione art.7"
            }
            //Devo aggiungere caso FM proprietario è azienda UE => esenzione
            else {
                vat = ((amount * 22) / 100);
            }

            item.amount = IT.currency(amount);
            item.vat = total.esenzione === "" ? IT.currency(vat) : "(N2.1)";
            item.total = IT.currency(amount + vat);

            total.amount = amount;
            total.vat = vat;

            if (data.cleaning) {
                total.esenzione += "N1 - esenzione art.15"
                items.push({
                    description: data.cleaning_title ? data.cleaning_title : "Pulizia finale",
                    qty: 1,
                    amount: IT.currency(data.cleaning),
                    vat: "(N1)", //IT.currency(0) +
                    total: IT.currency(data.cleaning)
                });

                total.amount += data.cleaning;
            }

            if (data.linen) {
                items.push({
                    description: data.linen_title ? data.linen_title : "Biancheria",
                    qty: 1,
                    amount: IT.currency(data.linen * data.guests),
                    vat: "(N1)", //IT.currency(0)+
                    total: IT.currency(data.linen * data.guests)
                });

                total.amount += data.linen * data.guests;
            }
        }

        item && items.push(item);

        total.value = IT.currency(total.amount + total.vat);
        total.amount = IT.currency(total.amount);
        total.vat = IT.currency(total.vat);

        return { items: items, total: total };
    },

    getAction(data) {
        if (data.istate === 3) return { label: "Segna Risolta", item: data, state: 5, unsolved: true, message: "Attività segnata come risolta" }
        else if (data.istate === 4) return { label: "Approva", item: data, state: 5, message: "Attività approvata" }
        else if (data.istate === 5) return { label: "Segna da Approvare", item: data, state: 4, message: "Attività spostata da approvare" }
        else if (data.istate === 6 || data.istate === 7) return { label: "Ripristina", item: data, state: data.warnings && data.warnings.length > 1 ? 3 : 4, unsolved: true, message: "Attività Ripristinata" }
    },

    vat(item) {
        let value = [];

        if (item.invoicing === 'O') {
            value.push("SUBLOCAZIONE esenzione iva art. 10");
        }
        else if (item.invoicing === 'C') {
            if (item.continent === "UE" && item.company) {
                value.push("AZIENDA UE esenzione iva art. 7");
            }
            else {
                value.push("CITTADINO " + item.continent + " iva 22% ex art. 16");
                value.push("RIMBORSO SPESE esenzione iva ex art. 15");
            }
        }
        else {
            value.push("iva 22% ex art. 16"); //In realtò devo controllare se propretario è AZIENDA UE
            value.push("RIMBORSO SPESE esenzione iva ex art. 15");
        }

        return value;
    },
    intent: {
        NOTE({ data }) {
            const item = data.item;
            item.$note = data.value;
            data.item.save();
        },

        CHANGE_STATE({ data }) {
            const item = data.item;

            if (!item) return;

            item.$istate = data.state;

            if (data.unsolved) item.$unsolved = true;

            item.save().then(() => {
                console.log("STATE CHANGED");
                notification.info({ message: data.message })
                //qui devo aggiornare liste => più complicato meglio chiamare una lista per volta...
            })
        },

        EDIT_TAXABLE({ data }) {
            const reference = {};
            this.context.openModal({
                kind: "confirm",
                content: <Einvoicecard source={data} parent={reference} />,
                title: "DESTINATARIO FATTURA",
                okText: "CONFERMA",
                cancelText: "ANNULLA",
                centered: true,
                width: 980,
                icon: null,
                //cancelButtonProps: { className: "btn-ghost rounded-full" },
                okButtonProps: { className: "btn-dark bg-dark rounded-full hover:bg-[#FAC710]" },
                onOk: (close) => {
                    reference.vm.emit("SAVE").then(r => {
                        console.log("SUBMIT INVOICE", r)
                        if (r.data) {
                            close();
                            this.update();
                        }
                    });
                },
            })
        },

        EDIT_INV({ data }) {
            const reference = {};
            this.context.openModal({
                kind: "confirm",
                content: <Einvoicelite source={data} parent={reference} />,
                title: "DATI FATTURA",
                okText: "CONFERMA",
                cancelText: "ANNULLA",
                centered: true,
                width: 980,
                icon: null,
                //cancelButtonProps: { className: "btn-ghost rounded-full" },
                okButtonProps: { className: "btn-dark bg-dark rounded-full hover:bg-[#FAC710]" },
                onOk: (close) => {
                    reference.vm.emit("SAVE").then(r => {
                        console.log("SUBMIT INVOICE", r)
                        if (r.data) {
                            close();
                            this.update();
                        }
                    });
                },
            })
        },

        PREVIEW({ data }) {
            this.context.openModal({
                kind: "info",
                content: <EInvoiceView item={data} />,
                title: "DETTAGLIO FATTURA",
                okText: "CHIUDI",
                //cancelText: "ANNULLA",
                centered: true,
                width: 980,
                icon: null,
                //cancelButtonProps: { className: "btn-ghost rounded-full" },
                okButtonProps: { className: "btn-dark bg-dark rounded-2xl hover:bg-[#FAC710]" },
                onOk: () => {
                    this.update();
                },
            })
        },

    }
});