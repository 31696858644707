import React from "react";
import { DataModel, core, $Data } from "@essenza/react";

export function TransactionModel() {
    DataModel.call(this);
}

core.prototypeOf(DataModel, TransactionModel, {
    etype: "transaction",

    report(interval) {
        return this.ExecuteQuery("financial_report", interval.toISODate(), {
            cast: source => {
                if (source) {
                    source.debit = $Data.cast(source.debit, this.etype)
                }
                return source;
            }
        });
    },

    refresh(){
        return this.ExecuteApi("transaction_refresh", null, { apiUrl: ""});
    }
});