import { DataModel, core, } from "@essenza/react";

export function RoomModel() {
    DataModel.call(this);
}

core.prototypeOf(DataModel, RoomModel, {
    etype: "room",
    
    getAll(){
        return this.ExecuteQuery("rooms");
    },

    report(interval){
        return this.ExecuteQuery("room_report", interval.toISODate());
    },

    send(canvas, data) {
        data.image = canvas.toDataURL("image/png").replace('data:image/png;base64,', '');
        return this.ExecuteApi("send_report", data );
        //return this.ExecuteApi("send_report", { formFile: img.replace('data:image/png;base64,', ''), month: "***prova***Giugno 2024", landlord: "Jacopo Tartaglia", email: "info@kosinformatica.it" });
    },

    downloadAll(data) {
        console.log("DOWNLOAD ALL", data);
        return this.ExecuteApi("export_all_report", data,  { excludeParams: true } );
        //return this.ExecuteApi("send_report", { formFile: img.replace('data:image/png;base64,', ''), month: "***prova***Giugno 2024", landlord: "Jacopo Tartaglia", email: "info@kosinformatica.it" });
    },

    approve(date, id){
        date = date.toISOString().substring(0, 10);
        return this.ExecuteApi("approve_summary", {date, id});
    },

});