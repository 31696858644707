import React from "react";
import { useWidget, Widget, core, FormVM, useForm, Form, FormItem } from "@essenza/react";
import { Input, InputNumber, Select, Radio, Checkbox } from "antd";
import { FaPercentage } from "react-icons/fa";
import { MdOutlineCleaningServices } from "react-icons/md";
import { BiBlanket } from "react-icons/bi";
import { LuTags } from "react-icons/lu";
import { CgLoadbarDoc } from "react-icons/cg";
import { NumericFormat } from 'react-number-format';


const eFormat = { TO: 2, FROM: 1, TO_AND_FROM: 3 };

function currencyFormatter2(format) {
	this.format = format || "it-IT";
	this.direction = eFormat.FROM;

	this.format = function (value) {
		console.log("CURRENCY TO", new Intl.NumberFormat(this.format, { style: 'currency', currency: 'EUR' }).format(value));
		return new Intl.NumberFormat(this.format, { style: 'currency', currency: 'EUR' }).format(value); //, currency: 'EUR' }
	};

	this.parse = function (value) {
		console.log("CURRENCY FROM", value.replace(/[^0-9\,-]+/g, "").replace(',', '.'));
		return value.replace(/[^0-9\.\,-]+/g, "");//Number(value.replace(/[^0-9\,-]+/g,"").replace(',','.'));
	}
}

const euro = new Intl.NumberFormat("it-IT", {
	style: "currency",
	currency: 'EUR'
});

const currencyFormatter = value => {
	let v = euro.format(value);
	return '€ ' + v.substring(0, v.length - 1);
};

const currencyParser = val => {
	try {
		// for when the input gets clears
		if (typeof val === "string" && !val.length) {
			val = "0.0";
		}

		// detecting and parsing between comma and dot
		var group = new Intl.NumberFormat("it-IT").format(1111).replace(/1/g, "");
		var decimal = new Intl.NumberFormat("it-IT").format(1.1).replace(/1/g, "");
		var reversedVal = val.replace(new RegExp("\\" + group, "g"), "");
		reversedVal = reversedVal.replace(new RegExp("\\" + decimal, "g"), ".");
		//  => 1232.21 €

		// removing everything except the digits and dot
		reversedVal = reversedVal.replace(/[^0-9.]/g, "");
		//  => 1232.21

		// appending digits properly
		const digitsAfterDecimalCount = (reversedVal.split(".")[1] || []).length;
		const needsDigitsAppended = digitsAfterDecimalCount > 2;

		if (needsDigitsAppended) {
			reversedVal = reversedVal * Math.pow(10, digitsAfterDecimalCount - 2);
		}

		return Number.isNaN(reversedVal) ? 0 : reversedVal;
	} catch (error) {
		console.error(error);
	}
};

/*const euro = new Intl.NumberFormat('it-IT', {
  style: 'currency',
  currency: 'EUR',
});*/

export function Roomcard({ source }) {
	const vm = useWidget(RoomcardVM);
	const form = useForm(source, { rules: vm.rules });

	return (<Widget>
		<Form form={form} layout="vertical" className="flex flex-col gap-3">
			<FormItem label="Commission" name="commission">
				<Input>
				</Input>
			</FormItem>
			<FormItem label="Icommission" name="icommission">
				<Input>
				</Input>
			</FormItem>
			<FormItem label="Cleaning" name="cleaning">
				<Input>
				</Input>
			</FormItem>
			<FormItem label="Linen" name="linen">
				<Input>
				</Input>
			</FormItem>
			<FormItem label="Id" name="id">
				<Input>
				</Input>
			</FormItem>
			<FormItem label="Guest" name="guest">
				<Input>
				</Input>
			</FormItem>
			<FormItem label="Guestmax" name="guestmax">
				<Input>
				</Input>
			</FormItem>
			<FormItem label="Idkb" name="idkb">
				<Input>
				</Input>
			</FormItem>
			<FormItem label="Invoicing" name="invoicing">
				<Input>
				</Input>
			</FormItem>
			<FormItem label="Qtag" name="qtag">
				<Input>
				</Input>
			</FormItem>
			<FormItem label="Key" name="key">
				<Input>
				</Input>
			</FormItem>
			<FormItem label="Kind" name="kind">
				<Input>
				</Input>
			</FormItem>
			<FormItem label="Floor" name="floor">
				<Input>
				</Input>
			</FormItem>
			<FormItem label="Code" name="code">
				<Input>
				</Input>
			</FormItem>
		</Form>
	</Widget>)
}

export function Roomlitecard({ source }) {
	const vm = useWidget(RoomcardVM);
	const form = useForm(source, { rules: vm.rules });

	if (!source) return null;
	vm.setState(source);
	return (<Widget>
		<Form form={form} layout='vertical' className="flex flex-col gap-3 mt-4" autosave >
			<div className="flex gap-3 flex-wrap">
				<FormItem className="flex-1" label="Tipo Fatturazione" name="invoicing">
					<Select onSelect={v=>{vm.invoicing = v; vm.update();}} variant="filled" placeholder={<CgLoadbarDoc className="text-black" />} options={[
						{ value: 'O', label: <span>Ospite importo completo</span> },
						{ value: 'C', label: <span>Ospite solo commissioni</span> },
						{ value: 'F', label: <span>Proprietario fine mese</span> },
					]} ></Select>
				</FormItem>
				{vm.invoicing !== 'O' && <>
					<FormItem className="flex-1" label="Commissione" name="commission">
						<InputNumber className="w-full" prefix={<FaPercentage />} variant="filled"></InputNumber>
					</FormItem>
					<FormItem className="flex-1 " label="Commissione Calcolata " name="icommission" extra="(spese di pulizia e biancheria)">
						<Radio.Group  >
							<Radio value={1}>Al Lordo </Radio>
							<Radio value={2}>AL netto </Radio>
						</Radio.Group>
					</FormItem>
				</>}
			</div>

			<div className="flex flex-wrap space-x-3 mt-4 items-end">

				<FormItem className="flex-1" label="Costo pulizia per soggiorno" name="cleaning" trigger="onValueChange" getValueFromEvent={v => v.floatValue} >
					<NumericFormat className="pl-7" customInput={Input} prefix="€" decimalSeparator="," thousandSeparator="." fixedDecimalScale />
				</FormItem>
				{/* <MdOutlineCleaningServices className="absolute ml-2 mt-11" /> */}

				<FormItem className="flex-1" label="Costo biancheria per ospite" name="linen" trigger="onValueChange" getValueFromEvent={v => v.floatValue}>
					<NumericFormat className="pl-7" customInput={Input} prefix="€" decimalSeparator="," thousandSeparator="." decimalScale={2} />
				</FormItem>
				{/* <BiBlanket className="absolute ml-2 mt-11" /> */}

				<FormItem className="flex-1" label="Costo pulizia in airbnb" name="airbnb" trigger="onValueChange" getValueFromEvent={v => v.floatValue} >
					<NumericFormat className="pl-7" customInput={Input} prefix="€" decimalSeparator="," thousandSeparator="." fixedDecimalScale />
				</FormItem>
			</div>

			{/* <div>
				<button className="btn-dark bg-black float-right w-32 rounded-full" onClick={() => vm.emit("SAVE")}>
					Conferma
				</button>
			</div> */}
		</Form>
	</Widget>)
}

export function RoomcardVM() {
	FormVM.call(this);
	this.room = null;
}

core.prototypeOf(FormVM, RoomcardVM, {
	setState(room){
		if(this.room !== room){
			this.room = room;
			this.invoicing = this.room.invoicing;
		}
	},
	$intent: {

	}
});
