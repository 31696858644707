import React, { useMemo, useState } from "react";
import { useWidget, Widget, core, ViewModel, useForm, Form, FormItem, FormVM, } from "@essenza/react";
import { Checkbox, DatePicker, Input, Radio, Select, } from "antd";
import { NumericFormat } from 'react-number-format';
import { IT } from "../../core/utils";

export function TransactionSpinoff({ item, parent }) {
    const vm = useWidget(SpinoffVM);
    const [spinoff, setSpinoff] = useState(item?.spinoff || 0);
    const form = useForm(item, { rules: vm.rules });
    useMemo(() => {
        if (item) {

        }
    }, [item]);

    if (parent) parent.transactionvm = vm;

    const onChange = (e) => {
        console.log('radio checked', e.target.value);
        setSpinoff(e.target.value);
    };
    //vm.$$init(() => vm.gross = source ? source.amount);
    return (<Widget>
        <div className="flex bg-black text-white w-full py-2 px-4 rounded-xl items-center">
            <div className="flex-1">Descrizione</div>
            <div className="flex-1">Importo netto</div>
            <div className="flex-1">Iva</div>
            <div className="flex-1"></div>
        </div>
        <div className="flex bg-gray-100 w-full py-2 px-4 rounded-xl items-center mt-2">
            <div className="flex-1">{item.label}</div>
            <div className="flex-1">{IT.currency(item.amount)}</div>
            <div className="flex-1">{IT.currency(item.vat_amount)}</div>
            <div className="flex-1"></div>
        </div>
        <Form form={form} layout="vertical" autosave>
            <FormItem className="flex-1" label="" name="spinoff">
                <Radio.Group className="py-4"  onChange={onChange} >
                    <Radio value={0}>Nessun scorporo</Radio>
                    {!item?.variable && <Radio value={1}>Scorpora per quota variabile</Radio>}
                    <Radio value={2}>Scorporo per Centro di costo</Radio>
                </Radio.Group>
            </FormItem>
            {
                spinoff === 1 && <>
                    <div className="flex bg-black mt-2 text-white w-full py-2 px-4 rounded-xl items-center">
                        <div className="flex-1">Descrizione</div>
                        <div className="flex-1">Importo lordo variabile</div>
                        <div className="flex-1">Iva</div>
                        <div className="flex-1">Residuo fisso {IT.currency(item.amount - item.var_amount)}</div>
                    </div>
                    <div className="flex bg-gray-100 w-full py-2 px-4 rounded-xl items-center mt-2">
                        <div className="flex-1">Quota variabile</div>
                        <FormItem label="" name="var_amount" className="flex-1 white-bg pr-2" trigger="onValueChange" getValueFromEvent={v => v.floatValue}>
                            <NumericFormat className="bg-white" defaultValue={item.variable_amount} customInput={Input} prefix="€" decimalSeparator="," thousandSeparator="." decimalScale={2} />
                        </FormItem>
                        <div className="flex-1">{IT.currency(IT.vat_amount(item.var_amount, item.vat_rate))}</div>
                        <div className="flex-1"></div>
                    </div></>
            }

            {
                spinoff === 2 && <>
                    <div className="flex bg-black mt-2 text-white w-full py-2 px-4 rounded-xl items-center">
                        <div className="flex-1">Centro di costo</div>
                        <div className="flex-1">Importo lordo</div>
                        <div className="flex-1">Iva</div>
                        <div className="flex-1">Importo residuo {IT.currency(item.amount - item.sales_amount - item.flat_amount - item.sublet_amount)}</div>
                    </div>
                    <div className="flex bg-gray-100 w-full py-2 px-4 rounded-xl items-center mt-2">
                        <div className="flex-1">Provvigioni e consulenze</div>
                        <FormItem label="" name="sales_amount" className="flex-1 white-bg pr-2" trigger="onValueChange" getValueFromEvent={v => v.floatValue}>
                            <NumericFormat className="" defaultValue={item.sales_amount} onValueChange={v => console.log(v)} customInput={Input} prefix="€" decimalSeparator="," thousandSeparator="." decimalScale={2} />
                        </FormItem>
                        <div className="flex-1">{IT.currency((item.sales_amount * item.vat_rate) / 100)}</div>
                        <div className="flex-1"></div>
                    </div>
                    <div className="flex bg-gray-100 w-full py-2 px-4 rounded-xl items-center mt-2">
                        <div className="flex-1">Gestione</div>
                        <FormItem label="" name="flat_amount" className="flex-1 white-bg pr-2" trigger="onValueChange" getValueFromEvent={v => v.floatValue}>
                            <NumericFormat defaultValue={item.flat_amount} onValueChange={v => console.log(v)} customInput={Input} prefix="€" decimalSeparator="," thousandSeparator="." decimalScale={2} />
                        </FormItem>
                        <div className="flex-1">{IT.currency((item.flat_amount * item.vat_rate) / 100)}</div>
                        <div className="flex-1"></div>
                    </div>

                    <div className="flex bg-gray-100 w-full py-2 px-4 rounded-xl items-center mt-2">
                        <div className="flex-1">Sublocazione</div>
                        <FormItem label="" name="sublet_amount" className="flex-1 white-bg pr-2" trigger="onValueChange" getValueFromEvent={v => v.floatValue}>
                            <NumericFormat defaultValue={item.sublet_amount} onValueChange={v => console.log(v)} customInput={Input} prefix="€" decimalSeparator="," thousandSeparator="." decimalScale={2} />
                        </FormItem>
                        <div className="flex-1">{IT.currency((item.sublet_amount * item.vat_rate) / 100)}</div>
                        <div className="flex-1"></div>
                    </div>
                </>
            }
        </Form>
    </Widget>
    )
}

export function SpinoffVM() {
    FormVM.call(this);
    this.vat = 1;
    this.net = 0;
    this.gross = null;
    this.vat_amount = 0;
}

core.prototypeOf(FormVM, SpinoffVM, {
    /*intent: {
        UPDATE({ data }) {

        }
    }*/
});