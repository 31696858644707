import React from "react";
import { useWidget, Widget, core, useForm, Form, FormItem, FormVM, useModel } from "@essenza/react";
import { Checkbox, Input, notification, Select, } from "antd";
import * as yup from 'yup';
import { Loader } from "../../component/loader";
import { IT } from "../../core/utils";
import { SuggestionModel } from "../../data/suggestion";
import { RiMailSendLine } from "react-icons/ri";

const { TextArea } = Input;

export function Suggestioncard({ source, date }) {
	const vm = useWidget(SuggestioncardVM);
	const [model, data] = useModel(SuggestionModel);

	vm.$$init(() => {
		vm.source = model.newInstance({ idproperty: source.idproperty, iduser: vm.context.session.profile.id });
		model.list(date, source.idproperty);
	});

	const form = useForm(vm.source, { rules: vm.$rules });

	//if (model.pending) return <Loader className="mt-12" />;

	const message = () => notification.info({ message: "Suggerimento inserito con successo", duration: 2 })

	return (<Widget>
		<Form form={form} layout="vertical" className="flex flex-col gap-3">
			<FormItem label="Categoria" name="category">
				<Select className="h-11" options={[{ label: "Consiglio", value: "Consiglio" }, { label: "Feedback", value: "Feedback" }]} variant="filled" />
			</FormItem>
			<FormItem label="Dettaglio" name="detail">
				<TextArea rows={4} />
			</FormItem>
			<FormItem label="" name="send" valuePropName="checked">
				<Checkbox>Invia al proprietario</Checkbox>
			</FormItem>
		</Form>
		<button className="btn-ghost rounded-full my-2" onClick={() => vm.emit("SAVE").then(message)}>Conferma</button>
		{
			data && <h1 className="text-sm">Ultimi Suggerimenti</h1> && data.map(suggestion => <div className="mt-2">
				<h1 className="flex items-center text-base font-bold gap-2">{suggestion.category} del {IT.date(suggestion.insertat)} {suggestion.send && <RiMailSendLine />}</h1>
				<p>{suggestion.detail}</p>
			</div>)
		}
	</Widget>
	)
}

export function SuggestioncardVM() {
	FormVM.call(this);
	this.$rules = yup.object({
		category: yup.string().required("Selezionare una categoria").nullable(),
		detail: yup.string().required("Richiesto *").nullable(),
	});

}

core.prototypeOf(FormVM, SuggestioncardVM, {
	/*intent:{
		async SUBMIT({data}){
			const validation = await this.form.validate(true);

			if (validation.isValid && validation.data.isMutated) {
				this.$suggestion.insert(validation.data.$$mutated);
			}
		}
	}*/
});
