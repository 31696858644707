import React, { useEffect } from 'react';
import { useWidget, UserVM, Widget, useForm, Form, FormItem } from "@essenza/react";
import { LuUser } from "react-icons/lu";
import { CiLock } from "react-icons/ci";
import { Button, Input } from 'antd';

export function Login({ user, rules }) {
    const vm = useWidget(UserVM);
    const form = useForm(user, { rules });

    console.log("LOGIN RENDER");

    useEffect(() => {
        let instance = form.target.getFieldInstance("email");
        instance.focus();
    }, []);

    return (
        <Widget>
            <Form form={form} layout='vertical' className="flex flex-col gap-3" >
                <FormItem label="Email" name="email">
                    <Input prefix={<LuUser className="text-xl"/>} className='h-11' variant='filled' placeholder="Email"></Input>
                </FormItem>
                <FormItem label="Password" name="password">
                    <Input.Password prefix={<CiLock className="text-xl"/>}  className='h-11' variant='filled' placeholder="password"></Input.Password>
                </FormItem>
                <button className="btn-dark bg-dark w-full mt-2" onClick={() => vm.emit("LOGIN")}>
                    Accedi
                </button>
                <Button className='float-right bg-white text-blue-900 mt-2' type="text" onClick={()=>vm.emit("GO_RECOVER")}>Password dimenticata?</Button>
            </Form>
        </Widget>
    )
}