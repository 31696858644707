import { BsArrowRightCircleFill } from "react-icons/bs";
import { IoIosAlert } from "react-icons/io";
import { RiSearch2Line } from "react-icons/ri";
import { TbHelpSquareRoundedFilled } from "react-icons/tb";
import { IoSync } from "react-icons/io5";
import { FaUserFriends } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import { CgAddR } from "react-icons/cg";
import { TbAlertCircleFilled } from "react-icons/tb";
import { TbMailFast } from "react-icons/tb";
import { useWidget, Widget, useValue, $Array, ViewModel, core, useFilter, useModel, FormItem, Form, useForm } from "@essenza/react";
import { Input, Button, Tooltip, Select, Popconfirm, Checkbox, Tag, Tour } from 'antd';
import { useEffect, useMemo, useRef, useState } from "react";
import { LandlordVVM, LandlordVista } from "./landlord";
import { RoomcardVM, Roomlitecard } from "../widget/room/roomcard";
import { PropertyModel } from "../data/property";
import { LuTags } from "react-icons/lu";
import { Menu } from "../component/menu";

export function PropertyVista() {
    const vm = useWidget(PropertyVM);
    const rooms = useValue("rooms")
    //useMemo(() => [{ value: 1, label: "Appartamento Bilocale" }, { value: 2, label: "Trilocale A" }, { value: 3, label: "Trilocale B" }, { value: 4, label: "Trilocale C" }, { value: 5, label: "Trilocale D" }, { value: 6, label: "Trilocale E" }], []);
    const [model, list] = useModel(PropertyModel);

    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const [open, setOpen] = useState(false);
    const steps = [
        {
            title: 'Elenco Proprietà',
            description: 'Risolvi proprietà con anomalie',
            placement: 'right',
            cover: (
                <img
                    alt="tour.png"
                    src="https://user-images.githubusercontent.com/5378891/197385811-55df8480-7ff4-44bd-9d43-a7dade598d70.png"
                />
            ),
            target: () => ref1.current,
        },
        {
            title: 'Ricerca proprietà',
            description: 'Filtro rapido per proprietà con anomalie da risolvere',
            target: () => ref2.current,
        },
        {
            title: 'Appartamenti',
            description: 'Seleziona gli appartamenti di una proprietà',
            target: () => ref3.current,
        },
    ];
    /*const list = useMemo(() => [
        {
            dealname: "Casa Con Vista Uzzano Castello", kind: "Appartamento", warning: 0,
            contacts: [
                { name: "Jacopo", surname: "Tartaglia", residence: "Via roma, 200 Firenze 50100", cf: "ABCDEF20Z00A000B" },
                { name: "Chiara", surname: "Cesca", residence: "Via roma, 200 Firenze 50100", cf: "ABCDEF20Z00A000B" }
            ],
            //rooms: []
            //rooms: [{ key: 1, label: "Appartamento Bilocale" }, { key: 2, label: "Trilocale A" }, { key: 3, label: "Trilocale B" }, { key: 4, label: "Trilocale C" }, { key: 5, label: "Trilocale D" }, { key: 6, label: "Trilocale E" }]
        },
        { dealname: "Casa con vista Matraia", kind: "Appartamento" },
        { dealname: "Bilocale nel cuore della città di Pescia", kind: "Appartamento", warning: true },
        { dealname: "Torre del Lago- Casa al Mare", kind: "Appartamento", warning: true },
        { dealname: "Viareggio - Appartamento affitti brevi via pisano 61", kind: "Appartamento" },
    ], []);*/

    const [inv, setInv] = useState(false);

    vm.setProperties(list);

    const property = vm.property;
    const apartment = vm.room;
    const state = property.$wstate//vm.useState(property);

    useEffect(() => {
        model.getAll();
    }, [])

    const [filter, data] = useFilter(list, {
        warning: i => i.warning,
        title: (i, v) => i.dealname.toLowerCase().includes(v.title.toLowerCase())
    })

    const onselect = (v, o) => {
        //property.rooms = property.rooms ? [...property.rooms, o] : [o];

        if (property.rooms)
            property.$rooms.push(o); else property.$rooms = [o];
        //vm.update();
    }

    const ondeselect = (v, o) => {
        //$Array.remove(property.rooms, room => room.value === o.value);
        const room = property.rooms.find(i => i.key === v)
        property.rooms.remove(room);
        //vm.update();
    }

    const onclear = (v, o) => {
        property.rooms = null;
        //vm.update();
    }

    const onselection = (v) => {
        if (!v) {
            property.save();
            vm.setApartment(property.rooms ? property.rooms[0] : null);
        }
    }
    const form = useForm(property, { rules: vm.rules });
    return <Widget>
        <div ref={vm.container} className="flex items-center gap-3 h-full p-3">

            <div className=" h-full flex flex-col gap-3 p-1 w-1/4 ">
                <div className="flex pl-2  items-center">
                    <span className="flex-auto font-bold text-lg">Proprietà in Gestione</span>
                    <Tooltip title="Guida" placement="bottom" >
                        <TbHelpSquareRoundedFilled onClick={() => setOpen(true)} className="text-3xl text-stone-400 min-w-6 cursor-pointer" />
                    </Tooltip>
                </div>
                {/* <button onClick={() => model.getManaged()}>TEST API</button> */}
                <div ref={ref2} className="">
                    <Input clas onChange={e => filter.apply({ title: e.target.value })}
                        suffix={<RiSearch2Line className="text-2xl " />}
                        placeholder="Ricerca proprietà" style={{ backgroundColor: "white" }}
                        className="px-6 rounded-full  bg-white"
                    />
                </div>

                <Menu lite bgcolor="bg-gray-500 text-white" source={[{ label: 'Tutte', value: false }, { label: 'Con avvisi', value: true, icon: <TbAlertCircleFilled className="text-2xl text-[#FAC710]" /> }]} itemClick={(_, item) => item.value ? filter.apply({ warning: true }) : filter.unset("warning", true)} />

                <div ref={ref1} className="rounded-3xl h-[calc(100vh-220px)] bg-white  flex flex-col gap-2 px-2 py-4">
                    <div className="overflow-auto px-2 ">
                        {
                            data && data.map((prop, i) => <>
                                {prop === vm.property ?
                                    <div className={"flex items-center bg-dark rounded-xl p-3 min-h-12 " + (i !== 0 && "mt-2")}>
                                        {prop.warning > 0 && <IoIosAlert className="text-3xl min-w-6 text-amber-500" />}
                                        <h1 className="font-bold text-white flex-auto mx-2">{prop.dealname}</h1>
                                    </div>
                                    :
                                    <div onClick={() => { vm.setProperty(prop); }} className={"flex items-center cursor-pointer bg-white rounded-xl p-3 hover:bg-slate-200  min-h-12 " + (i !== 0 && "mt-2")}>
                                        {prop.warning > 0 && <IoIosAlert className="min-w-6 text-amber-500" />}
                                        <h1 className="font-bold flex-auto mx-2">{prop.dealname}</h1>
                                    </div>
                                }
                            </>)
                        }
                    </div>
                </div>
            </div>

            <div className=" w-3/4 rounded-2xl rounded-tr-3xl pb-5 bg-white">
                <div className="bg-dark p-2 flex gap-3 w-full h-22 rounded-t-2xl text-white items-center">
                    <div className="ml-2 h-full flex-auto"><span className="font-bold  text-xl mr-2">{property.dealname}</span>
                    </div>
                    {/* <button className="btn-ghost-lite rounded-full" onClick={() => vm.emit("UPDATE")}>
                        Aggiorna ora
                    </button> */}
                    <h1>Ultimo aggiornamento il 18/06/2024</h1>
                </div>
                <div className="h-[calc(100vh-140px)] flex w-full px-2 ">
                    <div className="overflow-auto w-full px-3">
                        <h1 className=" font-bold text-sm my-2">Soggetto Fiscale (proprietario)</h1>

                        <div className=" flex gap-2 items-center">
                            <div className="bg-gray-100 py-3 px-5 min-h-24 rounded-2xl font-semibold w-1/3 text-lg flex items-center gap-3">
                                {//f09a1a arancio, #83eafe = celeste, #21bde6 = blu, #63d4d4 = blu/verde, bg-amber-500, #fac710 = giallo
                                    property.taxable
                                        ?
                                        <div className="flex-auto cursor-pointer font-bold text-md">
                                            <span>{property.taxable.lastname + ' ' + property.taxable.firstname}</span>
                                            <h1>{property.taxable.address}</h1>
                                            <h1>{property.taxable.cf || property.taxable.vatnumber}</h1>
                                        </div>
                                        : (state.nocotacts
                                            ? <><IoIosAlert className="flex-auto text-3xl text-amber-500 min-w-8" /><h1>Nessun contatto presente su hubspot associato alla proprietà</h1></>
                                            : <><IoIosAlert className="flex-auto text-3xl text-amber-500 min-w-8" /><h1>Seleziona il soggetto a cui emettere fattura</h1></>)
                                    // : <Tooltip overlayInnerStyle={{ fontWeight: 700, color: "black" }} color="#fac710" onClick={() => vm.emit("OPEN_CONTACT", property.contacts)} open={true} title={property.contacts?.length > 0 ? "Seleziona un soggetto fiscale" : "Nessun contatto hubspot associato"} placement="left" ><FaUserFriends className="text-white text-3xl cursor-pointer" /></Tooltip>
                                }
                                <FaUserFriends className="text-3xl cursor-pointer min-w-8" onClick={() => vm.emit("OPEN_CONTACT", property.contacts)} />
                            </div>
                            <Checkbox checked={property.invfor} onChange={e => vm.emit("INVFOR", { value: e.target.checked, item: property })} className="font-bold">Fatturazione per conto del proprietario</Checkbox>
                            {/*                           {onChange={e => setInv(e.target.checked)}
                                inv && <>
                                    <Tooltip title="Invia richiesta proprietario di abilitazione fatturazione Qonto" placement="bottom" ><TbMailFast className="text-3xl cursor-pointer" /></Tooltip>
                                    <Tag color="geekblue">Attesa invio Richiciesta</Tag>
                                </>
                            } */}
                        </div>
                        <Form form={form} className=" mt-2 flex gap-3" layout="vertical" autosave>
                            <FormItem className="basis-1/3 rounded-full" label="Etichetta Qonto" name="label">
                                <Input prefix={<LuTags />} variant="filled" ></Input>
                            </FormItem>
                            <FormItem className="basis-2/3 rounded-full w-full" label="Descrizione in fattura" name="title">
                                <Input prefix={<LuTags />} variant="filled" ></Input>
                            </FormItem>
                        </Form>
                        <div className="basis-3/4 flex flex-col mt-3">
                            <h1 className="font-bold text-sm flex-auto">Appartamenti</h1>
                            <div ref={ref3} className="flex items-center  gap-3 mt-2 p-3 bg-[#f1f1f1]  rounded-3xl">

                                <div className="flex flex-wrap gap-3 border-r border-r-black pr-4">
                                    {
                                        property.rooms && property.rooms.length > 0
                                            ? property.rooms.map((room, i) => room === vm.room ?
                                                <div className={"flex items-center bg-gray-500 rounded-xl p-3 min-h-12 "}>
                                                    {room.warning > 0 && <IoIosAlert className="text-3xl text-amber-500" />}
                                                    <h1 className="font-bold text-white flex-auto mx-2">{room.key}</h1>
                                                </div>
                                                :
                                                <div onClick={() => vm.setApartment(room)} className={"flex items-center cursor-pointer  rounded-xl p-3 hover:bg-white  min-h-12 "}>
                                                    {room.warning > 0 && <IoIosAlert className="text-xl text-amber-500" />}
                                                    <h1 className="flex-auto mx-2 font-semibold">{room.key}</h1>
                                                </div>)
                                            // <Menu onClick={e => setApt(e.key - 1)} selectedKeys={[apt + 1]} mode="horizontal" items={property.rooms} />
                                            : <>
                                                <IoIosAlert className="text-3xl text-amber-500" />
                                                <h1 className="font-bold text-lg text-zinc-800">Nessun appartamento associato</h1>
                                            </>
                                    }
                                </div>
                                <Popconfirm showCancel={false} destroyTooltipOnHide={true} placement="bottomLeft" trigger="click"
                                    title={<span className="font-bold flex-auto">Seleziona gli appartamenti da associare</span>} onOpenChange={onselection}
                                    description={<div className="h-60 w-[640px]">
                                        <Select className="w-full bg-white" defaultOpen={true} fieldNames={{ label: "key", value: "key" }}
                                            mode="multiple" allowClear optionFilterProp="label" onSelect={onselect} onDeselect={ondeselect} onClear={onclear}
                                            options={rooms} defaultValue={property.rooms} />
                                    </div>}>
                                    <CgAddR onClick={e => vm.setApartment(null)} className="text-2xl cursor-pointer" />
                                </Popconfirm>
                            </div>
                            <Roomlitecard source={apartment} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Tour open={open} onClose={() => setOpen(false)} steps={steps} />
    </Widget>
}

export function PropertyVM() {
    ViewModel.call(this);
    this.container = {};
    this.use(RoomcardVM).as("roomvm");
    this.context.listen("SELECT", ({ token }) => {
        this.taxable = token;
    });
    this.properties = null;
    this.room = null;
    this.property = null;
}

core.prototypeOf(ViewModel, PropertyVM, {
    useState(p) {
        const state = {};
        if (p) {
            let isrent = false;
            if (p.rooms) {
                p.rooms.forEach(el => {
                    if (!el.invoicing || (el.invoicing !== 'O' && !el.commission)) {//|| !el.cleaning || !el.linen
                        p.warning = true;
                        el.warning = 1;
                    }
                    else
                        el.warning = 0;
                })
                isrent = p.rooms[0].invoicing === 'O';
            }

            if (!p.contacts){
                state.nocotacts = !isrent;
                p.warning = !isrent;
            }
            else {
                p.contacts.forEach(el => {
                    if (!el.residence || !el.cf)
                        el.warning = 1;
                    else
                        el.warning = 0;
                });
            }

            if (!p.taxable){
                p.warning = !isrent;
                state.notaxable = !isrent;
            }
            else if (!p.taxable.residence || !p.taxable.cf) {
                //p.warning = true;
                state.incomplete = true;
            }

            
            p.$wstate = state;
        }
       
        return state;
    },

    setProperties(value) {
        if (this.properties !== value) {
            value && value.forEach(p=>this.useState(p));
            this.properties = value;
            this.property = value ? value[0] : { title: "Nessun immobile presente", $wstate:{} };
            this.room = this.property?.rooms ? this.property.rooms[0] : null;
            
        }
    },

    setApartment(value) {
        if (value !== this.room) {
            this.room = value;
            /*if (this.roomvm?.form) {
                this.roomvm.executeIntent("SAVE").then(() => this.update());//form.submit();//emit("VALIDATE")
            }
            else this.update();*/
            this.update();
        }
    },

    setProperty(value) {
        if (value !== this.property) {
            this.property = value;
            if (this.property) {
                //this.room && this.roomvm?.form?.submit();
                this.room = this.property.rooms ? this.property.rooms[0] : null;
                //this.property.save().then(() => this.update());//form.submit();//emit("VALIDATE")
                this.update();
            }
            else {
                this.room = null;
                this.update();
            }
        }
    },

    /* popRoom() {
         this.poproom = !this.poproom;
         this.update();
     },
 
     popContact() {
         this.poproom = !this.poproom;
         this.update();
     },*/

    intent: {
        OPEN_CONTACT({ data }) {
            this.context.openModal({
                kind: "confirm",
                content: <LandlordVista source={data} />,
                title: "CONTATTI HUBSPOT - Seleziona il soggetto fiscale a cui intestare la fattura",
                okText: "CONFERMA",
                cancelText: "ANNULLA",
                centered: true,
                width: 980,
                icon: <FaUserFriends className="text-3xl mr-2" />,
                cancelButtonProps: { className: "btn-ghost rounded-full" },
                okButtonProps: { className: "btn-dark bg-dark rounded-full hover:bg-[#FAC710]" },
                onOk: (close) => {
                    if (this.taxable) {
                        const result = this.taxable.form.validate(true);
                        result.then((r) => {
                            if (r.isValid) {
                                this.property.$taxable = r.data; //this.taxable;
                                this.property.save();
                                close();
                                this.update();
                            }
                        });
                        /*if(result.isValid){
                            this.property.$taxable = result.data; //this.taxable;
                            this.property.save();
                            //close();
                            this.update();
                        }*/
                        //return Promise.resolve();//return Promise.reject(new Error("Form invalid"));
                    }
                },
            })
        },
        INVFOR({ data }) {
            const item = data.item;
            item.$invfor = data.value;
            item.isMutated && item.save();
        }
    }
});