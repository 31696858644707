export function Transactions() {
    this.commission = new TransactionGroup();
    this.cleaning = new TransactionGroup();
    this.linen = new TransactionGroup();
    this.cost = new TransactionGroup();
    this.office_rental = new TransactionGroup();
    this.utility = new TransactionGroup();
    this.item = new TransactionGroup();
}

//operation_type
Transactions.prototype = {
    import(items) {
        if (items) {
            items.forEach(cost => {
                if (cost) {
                    let group = this.hasOwnProperty(cost.category) ? this[cost.category] : this.cost;
                    group.items.push(cost);
                    if (cost.enabled) {
                        //group.total += cost.amount + cost.vat_amount;
                        group.vat_amount += cost.vat_amount;
                        group.amount += cost.amount;
                    }
                }
            });
        }
        return this;
    },

    get extra(){
        return this.cost.items.concat(this.utility.items);
    }
}

export function TransactionGroup() {
    this.items = [];
    //this.total = 0;
    this.vat_amount = 0;
    this.amount = 0;
}

TransactionGroup.prototype = {
    Fill(item, enabled) {
        this.items.push(item);
        if(!enabled) return;
        this.vat_amount += item.vat_amount;
        this.amount += item.amount;
    },

    get net() {
        return this.amount - this.vat_amount;
    }
}

