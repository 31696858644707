import { TransactionGroup } from "./transaction";
import { $Data } from "@essenza/react";
import { IT } from "./utils";
export function Financial() {
    this.revenue = new TransactionGroup();
    this.fixed = new TransactionGroup();
    this.variable = new TransactionGroup();
    this.hasData = false;
}

//operation_type
Financial.prototype = {
    import(data, value) {
        if (data) {
            this.hasData = true;
            if (data.credit) {
                data.credit.forEach(credit => credit && (value === 0 || credit.value === value) && this.revenue.Fill(credit, credit.financial));
            }

            if (data.debit) {
                const igroup = ["all", "sales_amount", "flat_amount", "tax", "sublet_amount", "online"][value];
                data.debit.forEach(cost => {
                    if (cost) {
                        let group = cost.variable ? this.variable : this.fixed;
                        if (value !== 0 && cost.spinoff) {
                            const amount = cost[igroup];
                            if (amount > 0) {
                                group.Fill(this.Clone(cost, amount), cost.financial); //Clone DataObject...
                            }
                        }
                        else if (value === 0 || cost.value === value) {
                            if (cost.spinoff === 1 && !cost.variable) {
                                this.variable.Fill(this.Clone(cost, cost.var_amount), cost.financial);
                            }
                            group.Fill(cost, cost.financial);
                        }
                    }
                });
            }
        }
        return this;
    },

    Clone(item, value) {
        const clone = $Data.clone(item);//{ ...item };
        clone.amount = value;
        clone.vat_amount = IT.vat_amount(value, item.vat_rate);
        clone.cloned = true;
        return clone;
    },

    get GrossMargin() {
        return this.revenue.amount - this.variable.amount;
    },

    get GrossMarginPercent() {
        return this.revenue.amount > 0 ? this.GrossMargin / this.revenue.amount : 1;
    },

    get Ebitda() {
        return this.GrossMargin - this.fixed.amount;
    },
}


