import React, { useState } from "react";
import { useWidget, Widget, core, ViewModel} from "@essenza/react";
import { Input, Radio } from "antd";
import * as yup from 'yup';
const {TextArea} = Input;
export function SendReport({ apt, interval, parent }) {
	const vm = useWidget(SendReportVM);
    vm.$$init(()=>vm.setState(apt, interval, parent));

    const onchange = e => {
        vm.irecipient = e.target.value;
        setRecipient(vm.irecipient !== 0)
    }

    const [recipient, setRecipient] = useState(true); //useState(vm.state.incomplete);

	return (<Widget>
        {/* {
            vm.state.incomplete 
            ? apt.idproperty > 0 && <h1>Email mancante o nessun propretario selezionato per la struttura. Aggiornare i dati della struttura o inserire la mail destinatario manualmente.</h1>
            : <Radio.Group defaultValue={vm.irecipient} options={[{label: "Propretario", value: 0}, {label:"Altro destinatario", value:1}]} onChange={onchange} />
        } */}
		
        {
            recipient && <>
                <h1 className="font-bold mt-2 mb-1">Email Destinatario</h1>
                <Input onBlur={e=>vm.recipient.email = e.target.value} placeholder="Email"></Input>
                <h1 className="font-bold mt-2 mb-1">Note email</h1>
                <TextArea rows={3} onBlur={e=>vm.recipient.note = e.target.value} placeholder="Note"></TextArea>
            </>
        }

        {
            vm.error && <h1 className="text-red-600 text-base mt-1">Formato mail non valido</h1>
        }

	</Widget>)
}

export function SendReportVM() {
	ViewModel.call(this);
    this.state = null;
    this.recipient = {email: "", note: ""};
    this.landlord = null;
    this.irecipient = 0;
    this.error = null;
}

core.prototypeOf(ViewModel, SendReportVM, {
	setState(apt, interval, parent){
        parent.current = this;
        this.state = {label: interval.toMonthlyLabel() }; //, report: null, idproperty: apt.idproperty};
        this.landlord = {email: apt.email, nominative: apt.nominative,}

        this.irecipient = 1;

        /*if(!apt.email || apt.idproperty < 1) {
            this.state.incomplete = true;
            this.irecipient = 1;
        }

        if(interval.isMonthly){
            const date = new Date(interval.end.getTime());
            date.setDate(32);
            this.state.report = date;
        }*/
	},

    validate(){
        this.isvalid = true;
        if(this.irecipient === 0){
            this.state.email = this.landlord.email;
            this.state.nominative = this.landlord.nominative;
            return Promise.resolve(true);
        }
        else{
            //Controllo formato email???
            console.log("VALIDATION");
            this.state.email = this.recipient.email;
            this.state.note = this.recipient.note;
            const schema = yup.object({email: yup.string().required().email("Formato email non corretto").nullable()});
            return schema.validate(this.recipient).catch(error => {
                this.error = error;
                this.update();
                //throw new Error("invalid");
            });
            //notification.info({ message: error, duration: 2 })
        }
    },

	$intent: {

	}
});