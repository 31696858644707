import { Link, bool, small, string, decimal, double, float, int, long, date, money, char } from "@essenza/react";
const int2 = 1, varchar = 2, int4 = 6, int8 = 7, text = 2, numeric = 3, timestamptz = 8, timetz = 8, time = 8, timestamp = 8;
export function ConfigureApp(app) {
    //app.setBaseUrl("http://localhost:5008/");
    app.setBaseUrl("https://valente.liquido.cloud/");

    //app.configureService({ITask: app})
    app.role.configure(["ADMIN", "USER", "MANAGER", "AGENTE"]);

    app.configureType({
        users: {
            fields: {
                id: int, name: string, surname: string, email: string, password: string, npassword: string, itype: int,
                username: string, nusername: string, nemail: string, emailvalid: bool, phone: string,
                phonevalid: bool, twofactor: bool, idplatform: int, businessname: string, locked: bool,
                dlog: date, dlast: date, cf: string, vatnumber: string, street: string, num: string,
                locality: string, code: string, city: string, url: string, complete: bool
            },
        },

        place: {
            fields: {
                label: string, value: string
            }
        },

        contact: {
            fields: {
                nominative: string,
                address: string,
                city: string,
                cf: string,
                createdate: date,
                email: string,
                firstname: string,
                hs_id: string,
                idagent: string,
                lastmodifieddate: date,
                lastname: string,
                lifecyclestage: string,
                phone: string,
                pec: string,
                fax: string,
                residence: string,
                region: string,
                country: string,
                postcode: string,
                vatnumber: string,
                businessname: string,
                birth_place: string,
                citizenship: string,
                birth_date: string,
                area: string,
                iso: string,
                gender: string,
                legal: string,
                idoc: string,
                ndoc: string,
                doc_issue: null,
                doc_date: date,
                doc_expiry: date,
                idproperty: int4,
                company: bool,
                itype: int2, amount: numeric, vat_amount: numeric, note: string
            }
        },

        deal: {
            fields: {
                id: long, dealname: string, dealstage: string, address: string, ape: string, cadestral: string, cadestral_compliance: string, closedate: date, composition: string,
                createdate: date, hs_id: string, origin: string, purpose: string, seller: string, settlement: string, updateat: date
            },
            children: [
                { name: "contacts", etype: "contact", collection: true, link: Link.DOWN_WISE },]
        },

        landlord: {
            fields: {
                id: int4,
                warning: int2,
                lastname: varchar,
                email: varchar,
                address: varchar,
                postcode: varchar,
                area: varchar,
                city: varchar,
                cf: varchar,
                vatnumber: varchar,
                businessname: varchar,
                idhs: varchar,
                phone: varchar,
                idagent: varchar,
                lifecyclestage: varchar,
                domain: varchar,
                residence: varchar,
                region: varchar,
                country: varchar,
                firstname: varchar,
                idproperty: int4,
                company: bool,
                continent: varchar,
                pec: varchar,
                sdi: varchar,
                iso: varchar,
            }
        },

        room: {
            fields: {
                commission: numeric,
                icommission: int2,
                cleaning: numeric,
                linen: numeric,
                airbnb: numeric,
                id: int4,
                guest: int4,
                guestmax: int4,
                idkb: int8,
                invoicing: char,
                qtag: varchar,
                key: varchar,
                kind: varchar,
                floor: varchar,
                code: varchar,
                idproperty: int4,
            }
        },

        property: {
            fields: {
                budget: numeric,
                expire: date,
                state: int4,
                closedate: date,
                createdate: date,
                updateat: date,
                mq: int4,
                id: int4,
                idhs: varchar,
                dealstage: varchar,
                pipeline: varchar,
                cadastral: text,
                description: text,
                ikind: varchar,
                icontract: varchar,
                idagent: varchar,
                locality: varchar,
                address: varchar,
                dealname: varchar,
                idlandlord: int,
                label: varchar,
                idlabel: varchar,
                title: varchar,
                invfor: bool,
            },
            children: [
                { name: "contacts", etype: "landlord", collection: true, link: Link.DOWN_WISE },
                { name: "rooms", etype: "room", collection: true, link: Link.DOWN_WISE },
                { name: "taxable", etype: "landlord", collection: false, link: Link.UP_WISE }]
        },

        report: {
            fields: {
                id: long
            }
        },

        einvoice: {
            fields: {

                tax: numeric,
                extra: numeric,
                total: numeric,
                dcancel: timestamp,
                commission: numeric,
                managed: bool,
                canceled: bool,
                warnstate: int4,
                egroup: int2,
                pay: numeric,
                amount: numeric,
                istate: int2,
                ready: bool,
                company: bool,
                id: int4,
                invoicekind: int4,
                checkin: timestamp,
                checkout: timestamp,
                kbid: int4,
                rooms: int4,
                guests: int4,
                cost: numeric,
                vatnumber: varchar,
                kbnum: varchar,
                roomname: varchar,
                nationality: varchar,
                residence: varchar,
                channel: varchar,
                idota: varchar,
                referent: varchar,
                state: varchar,
                nation: varchar,
                email: varchar,
                lead: varchar,
                idrooms: varchar,
                paying: varchar,
                address: varchar,
                continent: varchar,
                iso: varchar,
                note: varchar,
                unsolved: bool,
                area: string,
                city: string,
                postcode: string,
                sdi: string,
                pec: string,
                cf: string,
                firstname: string, lastname: string, businessname: string,
                commission_title: string, linen_title: string, cleaning_title: string, gross_amount: numeric,
            }
        },

        transaction: {
            fields: {
                amount: numeric,
                amount_cents: int4,
                settled_balance: numeric,
                settled_balance_cents: int4,
                local_amount: numeric,
                local_amount_cents: int4,
                settled_at: timestamp,
                emitted_at: timestamp,
                vat_amount: numeric,
                vat_amount_cents: int4,
                vat_rate: numeric,
                attachment_lost: bool,
                attachment_required: bool,
                id: int8,
                updated_at: timestamp,
                enabled: bool,
                card_last_digits: varchar,
                category: varchar,
                idqonto: varchar,
                initiator_id: varchar,
                side: varchar,
                operation_type: varchar,
                currency: varchar,
                local_currency: varchar,
                label: varchar,
                subject_type: varchar,
                transaction_id: varchar,
                status: varchar,
                note: varchar,
                reference: varchar,
                idproperty: int4,
                var_amount: numeric,
                flat_amount: numeric,
                sales_amount: numeric,
                sublet_amount: numeric,
                spinoff: int4,
                variable: bool,
                igroup: int4,
                financial: bool,
            }
        },

        invoice: {
            fields: {
                performance_date: date,
                issue_date: date,
                total_amount_cents: int4,
                created_at: timestamp,
                paid_at: timestamp,
                einvoiceid: int8,
                due_date: date,
                id: int8,
                vat_amount_cents: int4,
                status: varchar,
                number: varchar,
                invoice_url: varchar,
                einvoicing_status: varchar,
                idqonto: varchar,
                client_id: varchar
            }
        },

        suggestion: {
            fields: {
                idproperty: int8,
                category: varchar,
                detail: text,
                send: bool,
            }
        }
    });
}