import React, { useState } from "react";
import { useModel, useWidget, Widget, core, } from "@essenza/react";
import { IT } from "../../core/utils";
import { Checkbox, DatePicker, Select, notification } from "antd";
import { FiDownload } from "react-icons/fi";
import { BsPrinter } from "react-icons/bs";
import dayjs from 'dayjs';
import { Menu } from "../../component/menu";
import { Loader } from "../../component/loader";
import { ReportVM } from "../../viewmodel/report";
import { TransactionModel } from "../../data/transaction";
import { Financial } from "../../core/financial";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import { CgArrowsExchangeAlt } from "react-icons/cg";
import { FiEdit } from "react-icons/fi";
import { IoSync } from "react-icons/io5";
import { Transactioncard } from "../transaction/transactioncard";
import { TransactionSpinoff } from "../transaction/spinoff";

export function FinancialReport({ source }) {
    const vm = useWidget(FinancialVM);
    const [model, data] = useModel(TransactionModel);

    model.request(m => {
        m.report(vm.interval);
    }, [vm.interval]);

    const message = () => notification.info({ message: "Disponibile a breve.", duration: 2 });

    const financial = new Financial();

    financial.import(data, vm.selected);

    return (<Widget>
        <div className="flex gap-2 items-center">
            <div className="flex gap-2 p-2 bg-white rounded-2xl font-bold">
                <DatePicker onChange={date => vm.onInterval(date.toDate(), true)} className="h-8 bg-white" format="DD-MM-YYYY" value={dayjs(vm.interval.start)} />
                <DatePicker onChange={date => vm.onInterval(date.toDate())} className="h-8 bg-white" format="DD-MM-YYYY" value={dayjs(vm.interval.end)} />
                <Select defaultValue={0} popupMatchSelectWidth={false} className=" bg-white max-w-24 h-8" onSelect={value => vm.onFilter(value)}
                    options={vm.list} />
            </div>
            <Menu source={vm.filteritem} unselected={vm.unselected} lite itemClick={(_, item) => vm.onMenuItem(item.value)} />
            <div className="flex-auto">
                <DatePicker placeholder="Mese" onChange={date => vm.onMonth(date)} className="h-8 bg-white w-20" picker="month" />
            </div>
            {/* <FiDownload className="text-2xl cursor-pointer" onClick={message} /> */}
            <BsPrinter className="text-2xl cursor-pointer" onClick={message} />
            <IoSync className="text-2xl cursor-pointer" onClick={()=>vm.emit("REFRESH")} />
        </div>
        <div className="w-full h-[calc(100vh-132px)] mt-3 flex">

            {
                model.pending ? <Loader className="mt-12" /> : <div className="w-full overflow-auto rounded-2xl">
                    <div className="w-full flex flex-col  gap-4 rounded-2xl">
                        {
                            financial.hasData ? <div className="bg-white p-2 gap-2 rounded-2xl">
                                <h1 className="font-bold text-lg ml-2 my-2" >Totali</h1>
                                <div className="flex gap-2 flex-wrap">
                                    <div className="bg-gray-100 min-w-40 h-28 p-8 rounded-xl flex-1">
                                        <h1>Ricavi</h1>
                                        <h1 className="text-2xl font-bold">{IT.currency(financial.revenue.amount)}</h1>
                                    </div>
                                    <div className="bg-gray-100 min-w-40 h-28 p-8 rounded-xl flex-1">
                                        <h1>Costi variabili</h1>
                                        <h1 className="text-2xl font-bold">{IT.currency(financial.variable.amount)}</h1>
                                    </div>
                                    <div className="bg-gray-100 min-w-40 h-28 p-8 rounded-xl flex-1">
                                        <h1>Costi generali</h1>
                                        <h1 className="text-2xl font-bold">{IT.currency(financial.fixed.amount)}</h1>
                                    </div>
                                </div>
                                <div className="flex flex-wrap gap-2 mt-2">
                                    <div className="bg-gray-100 min-w-40 h-28 p-8 rounded-xl flex-1">
                                        <h1>Gross margin</h1>
                                        <h1 className="text-2xl font-bold">{IT.currency(financial.GrossMargin)}</h1>
                                    </div>
                                    <div className="bg-gray-100 min-w-40 h-28 p-8 rounded-xl flex-1">
                                        <h1>Gross margin(%)</h1>
                                        <h1 className="text-2xl font-bold">{IT.percent(financial.GrossMarginPercent)}</h1>
                                    </div>
                                    <div className="bg-sec min-w-40 h-28 p-8 rounded-xl flex-1">
                                        <h1>Ebitda</h1>
                                        <h1 className="text-2xl font-bold">{IT.currency(financial.Ebitda)}</h1>
                                    </div>
                                </div>
                            </div> : <h1 className="ml-2 text-lg font-semibold">Nessun dato da visualizzare</h1>
                        }
                    </div>
                    <FinancialDetail group={financial.revenue} vm={vm} title="Ricavi" />
                    <FinancialDetail group={financial.variable} vm={vm} title="Costi Variabili" />
                    <FinancialDetail group={financial.fixed} vm={vm} title="Costi Generali" />
                </div>
            }
        </div>
    </Widget>)
}

function FinancialVM() {
    ReportVM.call(this);
    this.selected = 0;
    this.list = [{ label: "Generale", value: 0 }, { label: "Provvigioni e consulenze", value: 1 }, { label: "Gestioni", value: 2 }, { label: "Sublocazioni", value: 4 }];
}

core.prototypeOf(ReportVM, FinancialVM, {

    intent: {
        TRANSACTION_DETAIL({ data }) {
            console.log(data);
            data.emitted_at = new dayjs(data.emitted_at);
            this.context.openModal({
                kind: "info",
                content: <TransactionSpinoff item={data} />,
                title: "Dettaglio transazione",
                okText: "CHIUDI",
                //cancelText: "ANNULLA",
                centered: true,
                width: 980,
                icon: null,
                //cancelButtonProps: { className: "btn-ghost rounded-full" },
                okButtonProps: { className: "btn-dark bg-black rounded-2xl hover:bg-sec" },
                onOk: () => {
                    this.update();
                },
            })
        },

        TRANSACTION_EXCHANGE({ data }) {
            data.$variable = !data.variable;
            data.save().then(() => this.update())
        },

        COST_UPSERT({token}) {
            return this.transactionvm.emit("SAVE").then(r=> token.data = r.data);
        },

        REFRESH({token}) {
            //this.$transaction.refresh().then(() => this.context.closeLoader());
            this.context.openLoader(<Loader className="mt-6" />);
        }
    }
});

function FinancialDetail({ group, title, vm }) {
    const [detail, setDetail] = useState(false);
    const [editable, setEditable] = useState(false);
    const kind = ["Generale", "Prov e cons", "Gestioni", "Tasse", "Sublocazioni", "Online"];

    const onchange = (item, value) => {
        const obj = vm.context.newInstance("transaction", { id: item.id, enabled: item.financial });
        item.financial = value;
        obj.$financial= value;
        obj.save().then(r=>vm.update());
    }

    const oncost = () => {
        const side = title === 'Ricavi' ? 'credit' : 'debit';
        vm.emit("COST_UPSERT").then(result => {
            console.log(result);
            if(result.data){
                editable.value = editable.igroup;
                vm.$transaction.source[side].push(editable);
                group.items.push(editable);
                setEditable(false);
                vm.update();
            }
         })
    }
    return (<div className="bg-white p-2 gap-2 rounded-2xl mt-3">
        <div className="flex items-center pr-2">
            <h1 className="flex-auto font-bold text-lg ml-2 my-2" >{title}</h1>
            {!editable && <div className="p-2"><button className="btn-lite bg-gray-100 rounded-xl" onClick={() => setEditable(vm.context.newInstance("transaction", { variable: title === 'Costi Variabili', side: title === 'Ricavi' ? 'credit' : 'debit', operation_type: 'manual', financial: true }))}>Crea nuova voce</button></div>}
            <button className="flex gap-1 items-center" onClick={() => setDetail(!detail)}>Dettaglio {detail ? <IoIosArrowDown /> : <IoIosArrowUp />}</button>

        </div>

        {editable && <div className="py-3">
            <div className="bg-gray-600 text-white py-2 px-4 rounded-xl mb-2">Nuova voce</div>
            <Transactioncard source={editable} parent={vm} financial={true} />
            <div className="flex gap-3 mt-3">
                <button className="btn-dark bg-black rounded-xl h-10" onClick={() => setEditable(false)}>Annulla</button>
                <button className="btn-lite bg-sec rounded-xl" onClick={oncost}>Conferma</button>
            </div>
        </div>}

        <div className="flex bg-black text-white w-full py-2 px-4 rounded-xl items-center">
            <div className="basis-5/12">Descrizione</div>
            <div className="basis-2/12">Importo Lordo</div>
            <div className="basis-2/12">Importo netto</div>
            <div className="basis-1/12">Iva</div>
            <div className="basis-1/12">Catgoria</div>
            <div className="basis-1/12"></div>
        </div>
        <div className="flex bg-sec font-bold w-full py-2 px-4 rounded-xl items-center mt-2">
            <div className="basis-5/12">Totali</div>
            <div className="basis-2/12">{IT.currency(group.amount)}</div>
            <div className="basis-2/12">{IT.currency(group.net)}</div>
            <div className="basis-1/12">{IT.currency(group.vat_amount)}</div>
            <div className="basis-1/12"></div>
            <div className="basis-1/12"></div>
        </div>
        {
            detail && group.items.map(item => <div className="flex bg-gray-100 w-full py-2 px-4 rounded-xl items-center mt-2">
                <div className="basis-5/12">{item.label +  " " + (item.reference || "")}</div>
                <div className="basis-2/12">{IT.currency(item.amount  - (!item.cloned && item.spinoff === 1 ? item.var_amount : 0))}</div>
                <div className="basis-2/12">{IT.currency(item.amount - item.vat_amount - (item.spinoff === 1 && !item.cloned ? item.var_amount : 0))}</div>
                <div className="basis-1/12">{IT.currency(item.vat_amount)}</div>
                <div className="basis-1/12">{kind[item.value]}</div>
                <div className="basis-1/12 flex items-center gap-2">
                    {item.side === 'debit' && !item.cloned && <>
                        <CgArrowsExchangeAlt onClick={() => vm.emit("TRANSACTION_EXCHANGE", item)} className="text-xl cursor-pointer" />
                        <FiEdit onClick={() => vm.emit("TRANSACTION_DETAIL", item)} className="text-xl cursor-pointer" />
                    </>}
                    <Checkbox defaultChecked={item.financial} onChange={e => onchange(item, e.target.checked)} />
                    {/* {item.operation_type === 'manual' && <MdOutlineDeleteSweep onClick={() => vm.emit("REMOVE", item)} className="text-xl cursor-pointer" />} */}
                </div>
            </div>)
        }
    </div>)
}