
const INTERVAL = { LAST_MONTH: 'M' };

export function DateInterval(start, end) {
  this.start = start || new Date();
  this.start.setHours(0, 0, 0, 0);
  this.end = end || this.start;
  this.isMonthly = false;
}

DateInterval.prototype = {
  localMonth(index) {
    if (index instanceof Date) index = index.getMonth();
    if (index < 0) index = 12 + index;
    return ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"][index];
  },

  format: function (val) {
    if (val === INTERVAL.LAST_MONTH) {
      this.end = new Date();
      this.end.setDate(0);
      this.start = new Date(this.end.getTime());
      this.start.setDate(1);
    }
  },

  Month: function (m) {
    if(m===undefined)
      this.end = new Date();
    else if(m instanceof Date )
      this.end = m;
    else{
      this.end = new Date();
      this.end.setMonth(m);
    }

    this.end.setDate(32);
    this.end.setDate(0);

    this.start = new Date(this.end.getTime());
    this.start.setDate(1);
    this.isMonthly = true;
  },

  LastDays: function (days) {
    this.end = new Date();
    //this.end.setHours(this.end.getHours() - (this.end.getTimezoneOffset()/60))
    this.start = new Date(this.end.getTime() - (days * 24 * 60 * 60 * 1000));
    //this.start.setDate(this.end.getDate() - days);
    this.isMonthly = false;
  },

  LastMonth: function (last) {
    this.end = new Date();
    //this.end.setHours(this.end.getHours() - (this.end.getTimezoneOffset()/60))
    last = last || 1;
    if (last < 1) last = 1;

    for (let k = 0; k < last; k++) {
      this.end.setDate(0);
    }
    
    this.start = new Date(this.end.getTime());
    this.start.setDate(1);
    this.isMonthly = true;
  },

  clone: function () {
    return new DateInterval(this.start, this.end);
  },

  monthlyBeforeOf: function(date){
    if(!date) return false;
    if(typeof date === 'string') date = new Date(date);
    return date && this.isMonthly && this.end.getTime() < date.getTime();
  },

  toISO: function () {
    
    return { start: this.start.toISOString().substring(0, 19), end: this.end.toISOString().substring(0, 19) };
  },
  toISODate: function () {
    let start = new Date(this.start.getTime() - (this.start.getTimezoneOffset() * 60 * 1000));
    let end = new Date(this.end.getTime() - (this.end.getTimezoneOffset() * 60 * 1000));
    return { start: start.toISOString().substring(0, 10), end: end.toISOString().substring(0, 10) };
  },
  
  toIntervalString(){
    let sm = this.start.getMonth();
    let em = this.end.getMonth();
    return sm === em 
      ? this.start.getDate() + "-" + this.end.getDate() + " " + this.localMonth(sm) + " " + this.end.getFullYear()
      : this.start.getDate() + this.localMonth(sm) + " - " + this.end.getDate() + " " + this.localMonth(em) + " " + this.end.getFullYear();
  },

  toMonthlyLabel(){
    let sm = this.start.getMonth();
    return this.isMonthly ? (this.localMonth(sm) + " " + this.end.getFullYear()) : this.toIntervalString();
  }

}

export const Nullable = v => v || '';

export const IT = {
  date: d => Intl.DateTimeFormat('it-IT').format(d instanceof Date ? d : new Date(d)),
  currency: v => Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR', }).format(v),
  percent: v => Intl.NumberFormat('it-IT', { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2}).format(v),
  decimal: v => Intl.NumberFormat('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2}).format(v),
  round2: v=>Math.round(v*100)/100,
  vat_amount: (amount, vat) => amount - ((amount*100)/(100+vat)),
}

export const $CANVAS = {
  download(canvas, title){
    const link = document.createElement('a');
    link.download = (title || 'download') + '.png';
    link.href = canvas.toDataURL();
    link.click();
    //link.delete;
  }
}
/*export function DateIntervalB(format, date) {
    format = format || DateEnum.day;
    date = date || new Date();
    console.log("DateInterval", format, date);
    date.setHours(0, 0, 0, 0);
    let te;
    if (format === DateEnum.day) {
      te = new Date(date);
      te.setDate(date.getDate() + 1)
      return { ts: date.toISOString().substring(0, 19), te: te.toISOString().substring(0, 19) };
    }
    else if (format === DateEnum.week) {
      let day = date.getDay() - 1;
      if (day === -1)
        day = 6;
  
      date.setDate(date.getDate() - day);
      te = new Date(date);
      te.setDate(date.getDate() + 6);
      return { ts: date.toISOString().substring(0, 19), te: te.toISOString().substring(0, 19) };
    }
    else if (format === DateEnum.month) {
      date.setDate(1);
      let day = date.getDay() - 1;
      if (day === -1)
        day = 6;
  
      date.setDate(date.getDate() - day);
      te = new Date(date);
      te.setDate(date.getDate() + 35);
      return { ts: date.toISOString().substring(0, 19), te: te.toISOString().substring(0, 19) };
    }
  }*/