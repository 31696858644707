import { core, ViewModel } from "@essenza/react";
import {  DateInterval } from "../core/utils";

export function ReportVM() {
    ViewModel.call(this);
    this.unselected = false;
    this.interval = new DateInterval();
    this.interval.LastMonth();
    let month = this.interval.start.getMonth();
    this.list = [];
    this.selected = -3;
    this.filteritem = [
        { label: this.interval.localMonth(month), value: 1 },
        { label: this.interval.localMonth(month - 1), value: 2 },
        { label: this.interval.localMonth(month - 2), value: 3 },
        { label: '7gg', value: 7 },
        { label: '14gg', value: 14 },
        { label: '30gg', value: 30 }
    ];
}

core.prototypeOf(ViewModel, ReportVM, {
    onMenuItem(value) {
        this.interval = new DateInterval();
        value < 4 ? this.interval.LastMonth(value) : this.interval.LastDays(value);
        this.unselected = false;
        this.update();
    },

    onMonth(month) {
        this.interval = new DateInterval();
        this.interval.Month(month.toDate());
        this.unselected = true;
        this.update();
    },

    onInterval(date, start) {
        this.interval = this.interval.clone();

        if (start)
            this.interval.start = date;
        else
            this.interval.end = date;
        this.unselected = true;
        this.update();
    },

    

    onFilter(value) {
        this.selected = value;
        this.update();
    },

    /*formatItems(apt) {
        apt.totalcost = 0;

        apt.items = { commission: [], cleaning: [], linen: [], costs: [] }

        if (apt.costs) {
            apt.totalcost = 0;
            apt.costs.forEach(cost => {
                if (cost && cost.enabled)
                    apt.totalcost += cost.amount + cost.vat_amount;
            });
        }
    },*/
});