import React, { useMemo } from "react";
import { useWidget, Widget, core, ViewModel, useForm, Form, FormItem, FormVM, } from "@essenza/react";
import { Checkbox, DatePicker, Input, Select, } from "antd";
import { NumericFormat } from 'react-number-format';
import { IT } from "../../core/utils";
import * as yup from 'yup';

export function Transactioncard({ source, parent, financial }) {
    const vm = useWidget(TransactioncardVM);
    const form = useForm(source, vm.schema);
    useMemo(() => {
        if (source) {
            vm.gross = source.amount || null;
            vm.vat_amount = source.vat_amount || 0;
            vm.net = (source.amount - source.vat_amount) || 0;
            vm.vat = vm.vat_amount && vm.gross && vm.net ? ((vm.gross * 100) / vm.net) - 100 : 0;
            vm.target = source;
        }
    }, [source]);

    if (parent) parent.transactionvm = vm;

    //vm.$$init(() => vm.gross = source ? source.amount);
    return (<Widget>
        <Form form={form} layout="vertical" className="flex flex-col gap-3">
            <div className="flex gap-2 font-bold">
                <FormItem className="flex-1" label="Data" name="emitted_at">
                    <DatePicker className="h-8 bg-white" format="DD-MM-YYYY" />
                </FormItem>
                <div className="flex-1">
                    <h1 className="mb-2">Importo Lordo</h1>
                    <NumericFormat defaultValue={vm.gross} onValueChange={v => vm.setGross(v.floatValue)} onBlur={() => vm.update()} className="pl-7" customInput={Input} prefix="€" decimalSeparator="," thousandSeparator="." decimalScale={2} />
                </div>
                <div className="flex-1">
                    <h1 className="mb-2">Iva</h1>
                    <Select className="w-full h-11" onSelect={v => vm.setVat(v)} options={vm.vatenum} variant="filled" />
                </div>
                <div className="flex-1">
                    <h1 className="mb-2">Importo Iva</h1>
                    <h1 className="bg-gray-100 p-3 rounded-lg">{IT.currency(vm.vat_amount)}</h1>
                </div>
                <div className="flex-1">
                    <h1 className="mb-2">Importo netto</h1>
                    <h1 className="bg-gray-100 p-3 rounded-lg">{IT.currency(vm.net)}</h1>
                </div>

                {
                    financial ?
                        <FormItem className="flex-1" label="Centro di costo" name="igroup">
                            <Select className="h-11" options={vm.costsenum} variant="filled" />
                        </FormItem>
                        :
                        <FormItem className="flex-1" label="Categoria" name="category">
                            <Select className="h-11" options={vm.categoryenum} variant="filled" />
                        </FormItem>
                }
            </div>
            <FormItem label="Descrizione" name="label">
                <Input />
            </FormItem>
        </Form>
    </Widget>
    )
}
//defaultValue={category}
export function TransactioncardVM() {
    FormVM.call(this);

    this.schema = {
		rules: {
			default: yup.object({
				emitted_at: yup.date().required("Richiesto *").nullable(),
                igroup: yup.number().required("Richiesto *").nullable(),
                category: yup.string().required("Richiesto *").nullable(),
                label: yup.string().required("Richiesto *").nullable(),
			})
		}
	}
    this.categoryenum = [
        { label: "AFFITTO", value: "office_rental" },
        { label: "ALTRE SPESE", value: "cost" },
        { label: "LAVANDERIA", value: "linen" },
        { label: "PROVVIGIONE", value: "commission" },
        { label: "PULIZIA", value: "cleaning" },
        { label: "UTENZA", value: "utility" },
        { label: "VOCE IN FATTURA", value: "item" },
    ];

    this.costsenum = [
        { label: "Provvigioni e consulenze", value: 1 },
        { label: "Gestioni", value: 2 },
        { label: "Tasse", value: 3 },
        { label: "Sublocazioni", value: 4 },
        { label: "Online", value: 5 },
    ];

    this.vatenum = [
        { label: "No iva", value: 0 },
        { label: "Iva 22%", value: 22 },
        { label: "Iva 10%", value: 10 },
        { label: "Iva 5%", value: 5 },
    ];

    this.vat = 0;
    this.net = 0;
    this.gross = null;
    this.vat_amount = 0;
}

core.prototypeOf(FormVM, TransactioncardVM, {

    calculate() {
        this.net = ((this.gross || 0) * 100) / (100 + this.vat);//.toFixed(2);
        this.vat_amount = (this.gross || 0) - this.net;
        this.target.$amount = this.gross;
        this.target.$vat_amount = this.vat_amount;
        this.target.$vat_rate = this.vat;
        this.update();
    },

    setVat(value) {
        this.vat = value;
        this.calculate();
    },

    setGross(value) {
        this.gross = value;
        this.calculate();
    },

    /*intent: {
        SUBMIT({ data }) {
            console.log(data);
        },
    }*/
});

