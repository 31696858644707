import React, { useEffect, useMemo, useState, } from "react";
import { useModel, useWidget, Widget, core, ViewModel, useForm, Form, FormItem, $Array, FormVM } from "@essenza/react";
import { LandlordModel, } from "../../data/landlord";
import { Checkbox, Input, notification, Radio, Select, Table, Tooltip, } from "antd";
import { MdOutlineDeleteSweep, } from "react-icons/md";
import { FaEdit, } from "react-icons/fa";
import { Einvoicecard } from "../einvoice/einvoicecard";
import { SearchInput } from "../../component/searchinput";
import { DealModel } from "../../data/deal";
import { Loader } from "../../component/loader";
import { Contactcard, ContactcardVM } from "../contact/card";
import * as yup from 'yup';
import { Menu } from "../../component/menu";
import { DocumentCardVM } from "../document/document";

const { TextArea } = Input;

export function InvoiceRequest({ source }) {
    const vm = useWidget(DocumentCardVM);
    const [model, data] = useModel(DealModel);
    const form = useForm(vm.request.deal, { rules: vm.$rules });

    vm.$$init(() => vm.group.invoice = 1);

    const group = vm.group;

    const onsearch = v => {
        console.log(v);
        model.search(v);
        vm.selected = null;
        vm.update();
    }

    return (<Widget>
        <div className="p-3">
            <Menu source={vm.filteritem} lite itemClick={(_, item) => vm.onMenuItem(item.value)} />
            <div className="bg-white rounded-xl p-3">
                <SearchInput values={model.pending ? null : data} onSearch={onsearch} labelField="dealname" onSelect={v => vm.onSelect(v)} placeholder="Ricerca trattativa" />
                {/* <h1 className="text-lg font-bold my-4">Appartamento via squaglia, 364 Lucca</h1> */}
                {model.pending && <Loader className="mt-12" />}
                <Form form={form} layout="vertical" className="flex flex-col gap-3">
                    <h1 className="bg-black text-white w-full rounded-xl text-lg font-bold p-2 mt-3">Richiesta Fatturazione</h1>
                    {/* <FormItem className="flex-1" label="Note richiesta" name="note">
                        <TextArea rows={2}></TextArea>
                    </FormItem> */}
                </Form>
            </div>
            {vm.selected && <>
                <div className="bg-white rounded-xl p-3 mt-2">
                    <Select mode="multiple" fieldNames={{ label: "nominative", value: "hs_id" }} onDeselect={v => vm.removeContact(v)} onSelect={v => vm.addContact(v)} className="w-full" options={vm.selected?.contacts} variant="filled" placeholder="Seleziona contatti" />
                </div>
                <div>
                    {
                        vm.contacts.map(contact => <div className="mt-8 gap-3 bg-white rounded-xl p-3">
                            <Contactcard source={contact} group={group} />
                        </div>)
                    }
                </div>
                <button className="btn-dark bg-black rounded-2xl hover:bg-sec mt-2" onClick={() => vm.emit("INV_REQUEST")}>Invia Richiesta Fatturazione</button>
            </>
            }
        </div>
    </Widget>
    )
}