import { Input, Select } from "antd";
import { useRef } from "react";
import { useUI } from "@essenza/react";

export function SearchInput({ onSearch, onSelect, buttonClass, values, placeholder, labelField='label'}) {
    const ui = useUI();
    const ref = useRef();
    let value = useRef();

    const selected = useRef();

    const onsearch = v => {
        if (v)
            value.current = v;
        console.log(v);
    }

    const onclick = () => {
        console.log(ref.current);
        selected.current = null;
        onSearch && onSearch(value.current);
        value.current = null;
        ref.current.value = null;
    }

    const onselect = (v) => {
        console.log(v);
        selected.current = v;
        onSelect && onSelect(v);
        ui.update();
    }

    return (<div >
        {/* <Select onSearch={v=>onsearch(v)} ref={ref} className="min-w-72" open={values} showSearch options={values} /> */}
        <div className="flex items-center gap-2">
            <input ref={ref} onInput={e => onsearch(e.currentTarget.value)} className="w-full border border-gray-500 rounded-lg px-2" placeholder={placeholder} />
            <button onClick={onclick} className={buttonClass}>Cerca</button>
        </div>
        {
            selected.current ? <h1 className="text-lg font-bold mt-3 ml-1">{selected.current[labelField]}</h1> : (values && <div className="p-2 rounded-xl shadow-md">{values.map(v => <div onClick={() => onselect(v)} className="cursor-pointer rounded-lg hover:bg-gray-100">
                {v[labelField]}
            </div>)}</div>)
        }

    </div>)
}