import { Children, useState } from "react";

export function SelectableGroup({ className, children }) {
    const collection = Children.toArray(children);
    let [index, setIndex] = useState(0);
    return (
        <div className={className}>
            {
                Children.map(collection, (child, i) => {
                    //child.props.onClick = () => setIndex(i);
                    //child.props.$$index = i;
                    if(child.props.nolink) { if(i===index) index++; return  child;}
                    
                    let className = "";
                    if(child.props.className){
                        child.props.className.split(" ").forEach(s => {
                            if(s.indexOf("flex") !== -1) className += s;
                        });
                    }
                    return index === i ?
                        <div className="bg-gray-100 rounded-lg p-2 text-black">
                            {child}
                        </div>
                        : <span className={className} onClick={()=>setIndex(i)}>{child}</span>
                })
            }
        </div>
    )
}