import { DataModel, core, } from "@essenza/react";

export function PlaceModel() {
    DataModel.call(this);
}

core.prototypeOf(DataModel, PlaceModel, {
    etype: "place",

    countryList(value) {
        return this.ExecuteQuery("country_list",value ? {value} : null);
    },

    cityList(value){
        return this.ExecuteQuery("city_list", value ? {value} : null);
    },
});