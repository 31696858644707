import { useVista, useModel, Vista, ViewModel, core, UserVM, UserModel } from "@essenza/react";
import { Button, notification } from "antd";
import { useEffect } from "react";
import { Profile } from "../../widget/profile/profile";
import { Password } from "../../widget/profile/password";
import * as yup from 'yup';

export function ProfileVista() {
    const vm = useVista(ProfileVVM);
    const [user, data] = useModel(UserModel);

    useEffect(() => {
        user.profile();
    }, [user, vm]);

    return (
        <Vista>
            <div className="flex flex-col py-3 max-w-xl mx-auto gap-3">
                <button onClick={() => window.location.replace(window.location.origin)} type="text" className="bg-gray-400 p-2 hover:bg-gray-300 rounded-xl" ><b>LOGOUT</b></button>
                <div class="w-full p-6 bg-white shadow-md rounded-2xl ">
                    <Profile es-id="profile" user={data} schema={vm.schema} />
                    {/* <button className="btn-dark bg-green-800 mt-6 w-full rounded-lg" onClick={() => vm.emit("SAVE")}>Salva</button> */}
                </div>
                <div class="w-full p-6 bg-white drop-shadow shadow-md  rounded-2xl ">
                    <Password user={data} schema={vm.schema} />
                </div>
            </div>
        </Vista>
    )
}

export function ProfileVVM() {
    ViewModel.call(this);
    this.schema = {
		rules: {
			default: yup.object({
                email: yup.string().required("Email è una informazione richiesta.").email("Formato email non corretto"),
                npassword: yup.string().required("Password è una informazione richiesta.").matches(
                    /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\d\s:])([^\s]){8,24}$/,
                    "Deve contenere Almeno 8 Caratteri, almeno una maiuscola, almeno una minuscola, almeno un umero ed almeno un carattere speciale"
                ),
                cpassword: yup.string().required("Conferma password richiesta.").test('passwords-match', 'Passwords non corrispondenti', function (value) {
                    return this.parent.npassword === value;
                }),
            })
		}
	}
    
    this.bind(UserVM, "profile").as("profile").listen("PROFILE_UPDATE", ()=>{
        notification.success({message: "Profilo aggiornato con successo"})
    });

    this.use(UserVM).listen("PASSWORD_CHANGE", ()=>{
        notification.success({message: "Password aggiornata con successo"})
    });
}

core.prototypeOf(ViewModel, ProfileVVM, {
    rules: null,
    intent: {
        SAVE: async function () {
            const form = this.profile.form;
            const validation = await form.validate(true);
            if (validation.isValid) {
                console.log("USER FORM IS VALID", validation.data);
                validation.data.save().then(()=>notification.success({message: "Profilo aggiornato con successo"}));
            }
        },
    }
}); 