import React from 'react';
import { useWidget, UserVM, Widget, useForm, Form, FormItem } from "@essenza/react";

import { Button, Input } from 'antd';

export function Password({ user, schema }) {
    const vm = useWidget(UserVM);
    const form = useForm(user, schema);

    console.log("PASSWORD");

    return (
        <Widget>
            <Form form={form}  layout='vertical' className="flex flex-col gap-3" >
                <FormItem label="Password Attuale" name="password">
                    <Input.Password autoComplete="new-password" >
                    </Input.Password>
                </FormItem>
                <FormItem label="Nuova Password" name="npassword">
                    <Input.Password placeholder="password"></Input.Password>
                </FormItem>
                <FormItem label="Conferma Nuova Password" name="cpassword">
                    <Input.Password placeholder="conferma password"></Input.Password>
                </FormItem>
                <button className="btn-dark bg-dark mt-3 w-full rounded-lg" onClick={() => vm.emit("PASSWORD_CHANGE")}>
                    Aggiorna
                </button>
            </Form>
        </Widget>
    )
}