import React, { useEffect, useMemo, useState, } from "react";
import { useModel, useWidget, Widget, core, ViewModel, useForm, Form, FormItem, $Array, FormVM } from "@essenza/react";
import { LandlordModel, } from "../../data/landlord";
import { Checkbox, Input, notification, Radio, Select, Table, Tooltip, } from "antd";
import { MdOutlineDeleteSweep, } from "react-icons/md";
import { FaEdit, } from "react-icons/fa";
import { Einvoicecard } from "../einvoice/einvoicecard";
import { SearchInput } from "../../component/searchinput";
import { DealModel } from "../../data/deal";
import { Loader } from "../../component/loader";
import { Contactcard, ContactcardVM } from "../contact/card";
import * as yup from 'yup';
import { Menu } from "../../component/menu";

const { TextArea } = Input;
export function DocumentCard({ source }) {
    const vm = useWidget(DocumentCardVM);
    const [model, data] = useModel(DealModel);
    const [enabled, setEnabled] = useState(false);
    const [values, setValues] = useState(null);

    const form = useForm(vm.request.deal, { rules: vm.$rules });

    const group = vm.group;

    const onsearch = v => {
        console.log(v);
        model.search(v);
        vm.selected = null;
        vm.update();
    }

    return (<Widget>
        <div className="p-3">
            <Menu source={vm.filteritem} lite itemClick={(_, item) => vm.onMenuItem(item.value)} />
            <div className="bg-white rounded-xl p-3">
                <SearchInput values={model.pending ? null : data} onSearch={onsearch} labelField="dealname" onSelect={v => vm.onSelect(v)} placeholder="Ricerca trattativa" />
                {/* <h1 className="text-lg font-bold my-4">Appartamento via squaglia, 364 Lucca</h1> */}
                {model.pending && <Loader className="mt-12" />}
            </div>
            {vm.selected && <>
                <div className="bg-white rounded-xl p-3 mt-2">
                    <Select mode="multiple" onSelect={(_, o) => vm.onDocument(o)} className="w-full mb-2" options={vm.docs} variant="filled" placeholder="Seleziona documenti da compilare" />
                    <Select mode="multiple" fieldNames={{ label: "nominative", value: "hs_id" }} onDeselect={v => vm.removeContact(v)} onSelect={v => vm.addContact(v)} className="w-full" options={vm.selected?.contacts} variant="filled" placeholder="Seleziona contatti" />

                    {
                        group.realestate > 0 &&
                        <Form form={form} layout="vertical" className="flex flex-col gap-3">
                            <h1 className="bg-black text-white w-full rounded-xl text-lg font-bold p-2 mt-3">Dati immobile</h1>
                            <FormItem className="flex-1" label="Indirizzo Immobile" name="address">
                                <Input></Input>
                            </FormItem>
                            <FormItem className="flex-1" label="Dati catastali" name="cadestral">
                                <TextArea rows={2}></TextArea>
                            </FormItem>
                            <div className="flex flex-col md:flex-row gap-2 ">
                                <FormItem className="flex-1" label="Destinazione uso" name="purpose">
                                    <Select onSelect={onselect} className="h-11" options={vm.purpose} variant="filled" />
                                </FormItem>
                                <FormItem className="flex-1" label="Conformità catastale" name="cadestral_compliance">
                                    <Select onSelect={onselect} className="h-11" options={[{ label: "Conforme", value: "Conforme" },
                                    { label: "Non conforme", value: "Non conforme" },
                                    { label: "Pratica in corso", value: "Pratica in corso" },
                                    ]} variant="filled" />
                                </FormItem>
                                <FormItem className="flex-1" label="Composizione Immobile" name="composition">
                                    <Input></Input>
                                </FormItem>
                            </div>
                            <div className="flex gap-2">
                                <FormItem className="flex-1" label="Intestatario Immobile" name="seller">
                                    <Input></Input>
                                </FormItem>
                                <FormItem className="flex-1" label="Atti di provenienza" name="origin">
                                    <Input></Input>
                                </FormItem>
                                <FormItem className="flex-1" label="Stato Occupazione" name="settlement">
                                    <Input></Input>
                                </FormItem>
                            </div>
                        </Form>
                    }
                </div>
                <div>
                    {
                        vm.contacts.map(contact => <div className="mt-8 gap-3 bg-white rounded-xl p-3">
                            <Contactcard source={contact} group={group} />
                        </div>)
                    }
                </div>
                <button className="btn-dark bg-black rounded-2xl hover:bg-sec" onClick={() => vm.emit("FILL_DOCUMENT")}>Compila documenti</button>
            </>
            }
        </div>
    </Widget>
    )
}

export function DocumentCardVM() {
    FormVM.call(this);

    this.$rules = yup.object({
        address: yup.string().required("Richiesto *").nullable(),
        purpose: yup.string().required("Richiesto *").nullable(),
        cadestral: yup.string().required("Richiesto *").nullable(),
        //settlement: yup.string().required("Richiesto *").nullable(),
        //origin: yup.string().required("Richiesto *").nullable(),
        //cadestral_compliance: yup.string().required("Richiesto *").nullable(),
    });

    this.items = [{ label: "Compilazione Documenti", value: 0 },
    { label: "Richiesta Fatturazione", value: 1 },];

    this.mindex = 0;

    this.purpose = [{ label: "Albergo / hotel", value: "Albergo / hotel" },
    { label: "Appartamento", value: "Appartamento" },
    { label: "Villa", value: "Villa" },
    { label: "Terra-tetto", value: "Terra-tetto" },
    { label: "Fondo Commerciale", value: "Fondo Commerciale" },
    { label: "Ufficio", value: "Ufficio" },
    { label: "Terreno", value: "Terreno" },
    { label: "Garage", value: "Garage" },
    { label: "rustico/casale", value: "rustico/casale" },
    { label: "Edificio", value: "Edificio" },
    ]

    this.contacts = [];

    this.docs = [
        { label: "DEROGA AL TERMINE DELLA DELIBERA DI MUTUO", value: "1HcL96xtYEFQJETSx7iWQjNNq71M25qd5AuTXeaf1yLQ", group: { realestate: 1, contact: 1 } },
        { label: "INCARICO DI MEDIAZIONE PER VENDITA DI IMMOBILE", value: "1w9AVN3zbSx4oO-M9qtxKry-R87Aym1-kPn9BXVy5vak", group: { realestate: 1, birth: 1, contact: 1 } },
        { label: "INCARICO DI LOCAZIONE", value: "1xDO9SSg11KatdG4G2ek7fglZgOIw3PyJpfCg8ij4jKk", group: { realestate: 1, birth: 1, contact: 1 } },
        { label: "INCARICO DI RICERCA E DI MEDIAZIONE PER ACQUISTO IMMOBILIARE", value: "1RKFMcfDMVHN5wfTd_ZwRXTU5WOIb8hVNTvJE1mQLTu8", group: { birth: 1, contact: 1 } },
        { label: "MANDATO DI GESTIONE PER AFFITTI BREVI DI IMMOBILE ALTRUI", value: "1JGgk2I6q4Y2nW33vzBb3Xw5lOsrhmHrgtcD6EUL4-HE", group: { realestate: 1, birth: 1 } },
        { label: "MANDATO DI GESTIONE PER AFFITTI BREVI DI IMMOBILE ALTRUI (incasso cliente)", value: "143mmjbXPe4SryhTHL9CexJL0Lu9Sjutkt44_DZxJ6S0", group: { realestate: 1, birth: 1 } },
        { label: "PROPOSTA DI CONTRATTO PRELIMINARE DI VENDITA", value: "1iEawFwCeZjq6N1gTmgfBZLQffpor86n48ySYOEj-nPo", group: { realestate: 1, birth: 1, contact: 1 } },
        /*{ label: "PRIVACY", value: "" },
        { label: "SCHEDA VALUTAZIONE RISCHIO", value: 10 },
        { label: "[EN] MEDIATION AGREEMENT FOR THE SALE OF REAL ESTATE", value: "1nI02JXyKS7lwDBQXieWIfuCqucAGJ2s4nF_jTIFC7zU", group: { realestate: 1, birth: 1, contact: 1 } },
         { label: "[EN] MANDATE FOR RESEARCH AND MEDIATION FOR REAL ESTATE PURCHASE", value: 5 },
         */
        { label: "SCHEDA ADEGUATA VERIFICA DELLA CLIENTELA", value: "1o-UPkCUZgbLUbHmZjuOtJ7RgXxnSwkUmj6CPZYf4XF8", group: { identity: 1, birth: 1 } },
    ];

    this.selected = null;
    this.group = { realestate: 0, birth: 0, identity: 0, contact: 0, invoice: 0 }
    this.request = { groupids: "", documents: [], deal: {} }

    this.contactvm = this.use(ContactcardVM);
    this.contactvm.all = true;
}

core.prototypeOf(FormVM, DocumentCardVM, {
    onDocument(doc) {
        this.request.documents.push(doc);
        const group = doc?.group;
        if (group) {
            for (const key in group) {
                this.group[key]++;
            }
            this.update();
        }
    },

    onRemoveDocument(value) {
        const doc = $Array.split(this.request.documents, el => el.value === value);
        console.log("DOCUMENT REMOVE", doc);
        const group = doc?.group;
        if (group) {
            for (const key in group) {
                this.group[key]--;
            }
            this.update();
        }
    },

    onSelect(v) {
        v.mutation.clear();
        this.selected = v;
        this.request.deal = v;
        this.update();
    },

    addContact(id) {
        var el = this.selected?.contacts.find(el => el.hs_id === id);
        if (el) {
            el.mutation.clear();
            this.contacts.push(el);
            this.group.contact++;
            this.update();
        }
    },

    removeContact(id) {
        $Array.remove(this.contacts, el => el.hs_id === id)
        this.group.contact--;
        this.update();
    },

    intent: {
        async FILL_DOCUMENT({ data }) {
            const validation = await this.form.validate(true);

            let vm = this.contactvm;
            let val;
            let index  = 0;
            console.log("FILL DOCS", vm.source)
            while (vm && validation.isValid && vm.source?.length>index) {
                val = await vm.source[index].form.validate(true);
                console.log("VALIDATION", val);
                validation.isValid &= val.isValid;
                console.log("VALIDATION NEXT", vm)
                //vm = vm.next;
                index++;
            }

            if (validation.isValid) {
                this.request.groupids = "";
                for (const key in this.group) {
                    if (this.group[key] > 0) {
                        this.request.groupids += key + ',';
                    }
                }

                const deal = { ...this.selected };
                deal.contacts = this.contacts;
                this.request.deal = deal;

                deal.mutating = this.selected.__mutation?.mutated;
                deal.contacts && deal.contacts.forEach(c=> {
                    c.mutating = c.__mutation?.mutated;
                })

                console.log("FILL-DOC-DEAL", deal);


                const modal = this.context.openModal({
                    content: <Loader className="mt-12" />,
                    title: "Compilazione documenti",
                    centered: true,
                    width: 480,
                });

                new DealModel().fillDocument(this.request).then(r => {
                    modal.update({
                        content: <>
                            <a target="_blank" rel="noreferrer" onClick={() => {
                                this.context.navigate("home");
                                modal.destroy();
                            }} href={r.data}>{r.data}</a>
                        </>
                    })
                });
            }
        },

        async INV_REQUEST({ data }) {

            if(!this.selected || this.contacts.lenght === 0 ){
                notification.info({ title: "RICHIESTA FATTURAZIONE", message: "Nessun destinatario da fatturare selezionato." });
                return;
            }

            const validation = await this.form.validate(true);

            let vm = this.contactvm;
            let val;
            let index  = 0;
            while (vm && validation.isValid && vm.source?.length>index) {
                val = await vm.source[index].form.validate(true);
                console.log("VALIDATION", val);
                validation.isValid &= val.isValid;
                console.log("VALIDATION NEXT", vm)
                //vm = vm.next;
                index++;
            }

            if (validation.isValid) {
                let invoices = [];
                this.contacts.forEach(contact => {
                    const invoice = {taxable: contact, deal_hs_id: this.selected.hs_id, dealname: this.selected.dealname, note: contact.note };
                    const item = {amount: contact.amount, vat_amount: contact.vat_amount, description: this.selected.dealname, title: "Provvigione vendita" }
                    invoice.item = item;
                    invoices.push(invoice);
                });

                this.$deal.invoiceRequest(invoices).then(()=>{
                    notification.info({ title: "RICHIESTA FATTURAZIONE", message: "Richiesta inviata con successo" });
                    this.context.navigate("home");
                }) 
            }
        },
    }
});