import { useVista, useModel, Vista, ViewModel, core, UserVM, UserModel } from "@essenza/react";
import { Button, notification } from "antd";
import { useEffect } from "react";
import { Profile } from "../../widget/profile/profile";
import * as yup from 'yup';

export function UserVista() {
    const vm = useVista(UserVVM);
    const [user, data] = useModel(UserModel);

    useEffect(() => {
        const item = vm.context.navdata;
        user.item(item.id);
    }, [user, vm]);

    return (
        <Vista>
            <div className="flex place-content-center h-full items-center pt-4">
                <div class="w-full max-w-xl p-6 bg-white shadow-md drop-shadow rounded-xl ">
                    <Profile user={data} schema={vm.schema} roles={user.roles}/>
                    {/* <button className="btn-dark bg-sec w-full mt-4" onClick={() => vm.emit("SAVE")}>Salva</button> */}
                </div>
            </div>
        </Vista>
    )
}

export function UserVVM() {
    ViewModel.call(this);
    this.schema = {
		rules: {
			default: yup.object({
                email: yup.string().required("Email è una informazione richiesta.").email("Formato email non corretto"),
                npassword: yup.string().required("Password è una informazione richiesta.").matches(
                    /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\d\s:])([^\s]){8,24}$/,
                    "Deve contenere Almeno 8 Caratteri, almeno una maiuscola, almeno una minuscola, almeno un umero ed almeno un carattere speciale"
                ),
                cpassword: yup.string().required("Conferma password richiesta.").test('passwords-match', 'Passwords non corrispondenti', function (value) {
                    return this.parent.npassword === value;
                }),
            })
		}
	}
    this.use(UserVM).as("user").listen("PROFILE_UPDATE", ()=>{
        notification.success({message: "Profilo aggiornato con successo"})
    });
}

core.prototypeOf(ViewModel, UserVVM, {
    rules: null,
    intent: {
        SAVE: async function () {
            const form = this.user.form;
            const validation = await form.validate(true);
            if (validation.isValid) {
                console.log("USER FORM IS VALID", validation.data);
                validation.data.save();
            }
        },
    }
}); 