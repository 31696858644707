import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import dayjs from 'dayjs';
import 'dayjs/locale/it';
import locale from 'antd/locale/it_IT';
import { ConfigProvider } from 'antd';

dayjs.locale('it');
const theme = {
  token: {
    colorPrimary: '#000',
    colorSecondary: "#FAC710",
    colorFillTertiary: '#f1f1f1',
    colorFillSecondary: '#f1f1f1',
    //colorBorder: '#1677ff',
    borderRadius: 12,
    //colorBgElevated: "#ceedd0"
  },
  components: {
    Button: {
      defaultGhostBorderColor: '#055e35',
      defaultGhostColor: '#000',
      defaultHoverColor: "#FAC710",
      defaultHoverBorderColor: "#000",
      //defaultBg: '#055e35',
    },
    select: {
      optionSelectedBg: "#f4f4fc",
    },
    Input: {
      //activeBg: "#F2F4F6"
    },
    Menu: {
      itemHoverColor: '#FFFFFF'
    },
  },
}

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <ConfigProvider locale={locale} theme={theme}>
    <BrowserRouter basename="/">
      <App />
    </BrowserRouter>
  </ConfigProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
