import { useWidget, Widget, core, ViewModel } from "@essenza/react";
import { Button } from "antd";
import { PropertyUI } from "./ui/elements";

export function Uiux() {
    const vm = useWidget(UiuxVM);
    return (
        <Widget>
            <PropertyUI />
        </Widget>
    )
}

export function UiuxVM() {
    ViewModel.call(this);
}

core.prototypeOf(ViewModel, UiuxVM, {
    intent: { //swipe or override
        GO_HOME: ({ context }) => {
            context.navigate("home");
        },
    }
});