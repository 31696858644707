import React, { useState } from "react";
import { useWidget, Widget, core, ViewModel, useForm, Form, FormItem, FormVM, } from "@essenza/react";
import { Input, Tooltip, } from "antd";
import { BsArrowRightCircleFill } from "react-icons/bs";
import { FaUserEdit } from "react-icons/fa";

export function Landlordmap({ source }) {
	const vm = useWidget(LandlordmapVM);
	vm.$$init(() => vm.emit("SELECT", source?.length >0 ? source[0] : null));
	const onclick = (i, item) =>{
		vm.selected = i
		vm.emit("SELECT", item);
	}
	const format = value => value ? value : "#";

	return (<Widget>
		<div className="rounded-2xl h-full flex flex-col gap-2 p-3 bg-gray-100">
			{
				source.map((item, i) => <>
					{i === vm.selected ?
						<div className="flex items-center bg-black rounded-xl px-3 min-h-12">
							{/* {item.warning > 0 && <span className="rounded-full bg-amber-500 w-2 h-2"></span>} */}
							<h1 className="font-bold text-white flex-auto mx-2">{format(item.lastname) + ' ' + format(item.firstname)}</h1>
							{/* <BsArrowRightCircleFill className="text-2xl text-white min-w-6 cursor-pointer" /> */}
							{/* <Tooltip title="Sincronizza dati hubspot" color="gold" placement="right" >
								<IoSync className="text-2xl min-w-6 cursor-pointer text-white" />
							</Tooltip> */} 
						</div>
						:
						<div onClick={() => onclick(i, item)} className="flex items-center cursor-pointer rounded-xl p-3 hover:bg-slate-200  min-h-12">
							{/* {item.warning >0 && <span className="rounded-full bg-amber-500 w-2 h-2"></span>} */}
							<h1 className="font-bold flex-auto mx-2">{format(item.lastname) + ' ' + format(item.firstname)}</h1>
							{/* <FaUserEdit className="text-2xl min-w-6 cursor-pointer" /> */}
						</div>
					}
				</>)
			}
		</div>
	</Widget>
	)
}

export function LandlordmapVM() {
	ViewModel.call(this);
	this.selected = 0;
}

core.prototypeOf(ViewModel, LandlordmapVM, {
	intent: {
		SUBMIT({ data }) {
			console.log(data);
		},
	}
});
