import { useVista, Vista } from "@essenza/react";

import { Home } from "../widget/home";
import { Menu } from "../component/menu";
import { useState } from "react";
import { EInvoiceList, InvoiceList } from "../widget/invoice/list";
import { Logo } from "../layout/logo";

export function HomeVista() {
    const vm = useVista();

    const [index, setIndex] = useState(0);

    const items = [
        { label: 'Monitoraggio', value: 0 },
        { label: 'Da Fatturare', value: 1 },
        { label: 'Fatture', value: 2 },
    ];

    if(!vm.context.role.authorize("ADMIN,USER")){
        return <Logo lite className="my-12 max-w-xs block mx-auto"></Logo>
    }

    return (
        <Vista>
            <div className="flex gap-4 p-3 h-full">
                <div className="p-3 bg-white rounded-2xl min-w-40">
                    <Menu source={items} itemClick={i => setIndex(i)} />
                </div>
                <div className="w-full">
                    {index === 0 ? <Home /> : (index === 1 ? <EInvoiceList /> : <InvoiceList />)}
                </div>
            </div>
        </Vista>
    )
}