import React, { useMemo } from "react";
import { useWidget, Widget, core, ViewModel, } from "@essenza/react";
import { TbAlertCircleFilled } from "react-icons/tb";
import { IT } from "../../core/utils";
import { Input, notification } from "antd";
import { EnvoicePreview } from "./preview";
import { ReportCostView } from "../room/roomreport";
const { TextArea } = Input;

export function EInvoiceView({ item }) {
    if (!item) return null;

    return item.egroup === 1 || item.summary ? <IBookView data={item} /> : <RealEstateView data={item} />
}

export function IBookView({ data }) {
    const vm = useWidget(IEinvoiceVM);
    const action = vm.getAction(data);
    useMemo(() => vm.note = data.note, [data, vm]);
    return (<Widget>
        <div className="flex">
            <h1 className="font-bold text-lg flex-auto">{data.title}</h1>
            {/* <IoEyeOutline onClick={() => vm.emit("PREVIEW", data)} className="text-xl cursor-pointer" /> */}
        </div>

        <h1 className="font-semiboldbold text-base">{vm.kind[data.invoicing]}</h1>
        {/* <h1 className="font-semiboldbold text-base">{"Rif. " + data.ref + " del " + data.date}</h1> */}
        <div className="bg-dark text-white text-base rounded-xl pl-4 p-2 mt-2 font-semibold">
            {
                data.warnings && data.groupid !== 5 ? data.warnings.map(w =>
                    w ?
                        <div className=" flex gap-2">
                            <TbAlertCircleFilled className={"text-2xl text-[" + (w.state === 1 ? "#FAC710" : "#1687DE") + "]"} />
                            <h1>{w.code + ' ' + (w.description || '')}</h1>
                        </div> : null)
                    : "Nessuna anomalia presente"
            }
        </div>

        <div className="flex flex-wrap mt-2 gap-2">
            <div className="bg-white flex-1 w-min-24 h-18 p-2 rounded-xl">
                <h1>Soggiorno</h1>
                <h1 className="text-2xl font-bold">{IT.currency(data.invoicing === 'O' ? data.gross : data.netota)}</h1>
            </div>
            <div className="bg-white flex-1 w-min-24 h-18 p-2 rounded-xl">
                <h1>Extra</h1>
                <h1 className="text-2xl font-bold">{IT.currency(data.extra)}</h1>
            </div>
            {data.invoicing !== 'O' && <>
                <div className="bg-white flex-1 w-min-24 h-18 p-2 rounded-xl">
                    <h1>Commissioni</h1>
                    <h1 className="text-2xl font-bold">{ data.summary ? IT.currency(data.commission) : (data.commission + "%")}</h1>
                    <h1 className="text-base font-semibold">+ Iva</h1>
                </div>
                <div className="bg-white flex-1 w-min-24 h-18 p-2 rounded-xl">
                    <h1>Biancheria</h1>
                    <h1 className="text-2xl font-bold">{IT.currency(data.linen)}</h1>
                </div>
                <div className="bg-white flex-1 w-min-24 h-18 p-2 rounded-xl">
                    <h1>Pulizie</h1>
                    <h1 className="text-2xl font-bold">{IT.currency(data.cleaning)}</h1>
                </div>
            </>}

            <div className="bg-white flex-1 w-min-24 h-18 p-2 rounded-xl">
                <h1>Ospiti</h1>
                <h1 className="text-2xl font-bold">{data.guests}</h1>
            </div>
        </div>
        <ReportCostView source={data.costs} apt={data} />
        {/* <div className="bg-[#86888B] text-white rounded-xl pl-4 p-2 mt-2 font-semibold">
            {vm.vat(data).map(item => <h1>{item}</h1>)}
        </div>
        <TextArea value={vm.note} onChange={(e) => { vm.note = e.target.value; vm.update() }} onBlur={(e) => vm.emit("NOTE", { item: data, value: e.target.value })} className="w-full mt-2 bg-white" rows={2} placeholder="Aggiungi nota in fattura" />
        <div className="flex gap-2 justify-around mt-2">
            <button className="btn-dark bg-dark rounded-full" onClick={() => vm.emit("CHANGE_STATE", action)}>
                {action.label}
            </button>
            {data.istate !== 6 && data.groupid !== 7 &&
                <button className="btn-ghost rounded-full" onClick={() => vm.emit("CHANGE_STATE", { item: data, state: 6, message: "Attività sospesa" })}>
                    Sospendi
                </button>
            }
            {data.groupid !== 3 &&
                <button className="btn-ghost rounded-full" onClick={() => vm.emit("CHANGE_STATE", { item: data, state: 7, message: "Attività archiviata" })}>
                    Archivia
                </button>
            }
        </div> */}
    </Widget>
    )
}

export function IEinvoiceVM() {
    ViewModel.call(this);
    this.kind = { F: "Fattura al proprietario fine mese", O: "Fattura intero importo ad Ospite", C: "Fattura commissioni ad Ospite", V: "Fattura per vendita immobile", N: "Fattura per consulenza immobiliare" }
}

core.prototypeOf(ViewModel, IEinvoiceVM, {
    getAction(data) {
        if (data.istate === 3) return { label: "Segna Risolta", item: data, state: 5, unsolved: true, message: "Attività segnata come risolta" }
        else if (data.istate === 4) return { label: "Approva", item: data, state: 5, message: "Attività approvata" }
        else if (data.istate === 5) return { label: "Segna da Approvare", item: data, state: 4, message: "Attività spostata da approvare" }
        else if (data.istate === 6 || data.istate === 7) return { label: "Ripristina", item: data, state: data.warnings && data.warnings.length > 1 ? 3 : 4, unsolved: true, message: "Attività Ripristinata" }
    },

    vat(item) {
        let value = [];

        if (item.invoicing === 'O') {
            value.push("SUBLOCAZIONE esenzione iva art. 10");
        }
        else if (item.invoicing === 'C') {
            if (item.continent === "UE" && item.company) {
                value.push("AZIENDA UE esenzione iva art. 7");
            }
            else {
                value.push("CITTADINO " + item.continent + " iva 22% ex art. 16");
                value.push("RIMBORSO SPESE esenzione iva ex art. 15");
            }
        }
        else {
            value.push("iva 22% ex art. 16"); //In realtò devo controllare se propretario è AZIENDA UE
            value.push("RIMBORSO SPESE esenzione iva ex art. 15");
        }

        return value;
    },
    intent: {
        NOTE({ data }) {
            const item = data.item;
            item.$note = data.value;
            data.item.save();
        },

        PREVIEW({ data }) {
            this.context.openModal({
                kind: "info",
                content: <EnvoicePreview data={data} />,
                title: "ANTEPRIMA FATTURA",
                okText: "CHIUDI",
                //cancelText: "ANNULLA",
                centered: true,
                width: 980,
                icon: null,
                //cancelButtonProps: { className: "btn-ghost rounded-full" },
                okButtonProps: { className: "btn-dark bg-dark rounded-full hover:bg-[#FAC710]" },
                /*onOk: () => {
                    this.update();
                },*/
            })
        },

        CHANGE_STATE({ data }) {
            const item = data.item;

            if (!item) return;

            item.$istate = data.state;

            if (data.unsolved) item.$unsolved = true;

            item.save().then(() => {
                console.log("STATE CHANGED");
                notification.info({ message: data.message })
                //qui devo aggiornare liste => più complicato meglio chiamare una lista per volta...
            })
        },
    }
});

export function RealEstateView({ data, label }) {
    const vm = useWidget(IEinvoiceVM);

    return (<Widget>
        <h1 className="font-bold text-lg">{data.title}</h1>
        <h1 className="font-semiboldbold text-base">{data.kind}</h1>
        {/* <h1 className="font-semiboldbold text-base">{"Rif. " + data.ref + " del " + data.date}</h1> */}
        <div className="bg-dark text-white text-base rounded-xl pl-4 p-2 mt-2 font-semibold">
        {
                data.warnings && data.groupid !== 5 ? data.warnings.map(w =>
                    w ?
                        <div className=" flex gap-2">
                            <TbAlertCircleFilled className={"text-2xl text-[" + (w.state === 1 ? "#FAC710" : "#1687DE") + "]"} />
                            <h1>{w.code + ' ' + (w.description || '')}</h1>
                        </div> : null)
                    : "Nessuna anomalia presente"
            }
        </div>
        <div className="flex flex-wrap mt-2 gap-2">
            <div className="bg-white flex-1 w-min-24 h-18 p-2 rounded-xl">
                <h1>Totale</h1>
                <h1 className="text-2xl font-bold">{IT.currency(data.amount)}</h1>
                <h1 className="text-base font-semibold">{data.sub ? "no Iva" : "+Iva"}</h1>
            </div>
        </div>
        <div className="bg-[#86888B] text-white rounded-xl pl-4 p-2 mt-2 font-semibold">
            <h1>{data.eu}</h1>
            <h1>Nota interna da hubspot</h1>
        </div>
        {/* <TextArea className="w-full mt-2 bg-white" rows={2} placeholder="Aggiungi nota in fattura" />
        <div className="flex gap-2 justify-around mt-2">
            <button className="btn-dark bg-dark rounded-full" onClick={() => vm.emit("SAVE")}>
                {label}
            </button>
            {data.groupid !== 2 && data.groupid !== 3 &&
                <button className="btn-ghost rounded-full" onClick={() => vm.emit("SAVE")}>
                    Sospendi
                </button>
            }
            {data.groupid !== 3 &&
                <button className="btn-ghost rounded-full" onClick={() => vm.emit("SAVE")}>
                    Archivia
                </button>
            }
        </div> */}
    </Widget>
    )
}