import { DataModel, core, } from "@essenza/react";

export function DealModel() {
    DataModel.call(this);
}

core.prototypeOf(DataModel, DealModel, {
    etype: "deal",
    
    search(value) {
        return this.ExecuteQuery("deal_search", {value});
    },
    
    fillDocument(data){
        return this.ExecuteApi("fill_docs", data, { excludeParams: true });
    },
    
    invoiceRequest(data){
        return this.ExecuteApi("inv_req", data, { excludeParams: true });
    }

});