import { useVista, Vista, core, ViewModel } from "@essenza/react";
import { Landlordmap, LandlordmapVM } from "../widget/landlord/landlordmap";
import { Landlordcard, LandlordcardVM, Lordlitecard } from "../widget/landlord/landlordcard";
import { Einvoicecard, EinvoicecardVM } from "../widget/einvoice/einvoicecard";

export function LandlordVista({ source }) {
    const vm = useVista(LandlordVVM);
    if (!source) return <span>Nessun contatto presente</span>
    //vm.$$init(() => vm.selected = source[0]);
    return (
        <Vista>
            <div className="mx-auto h-full flex gap-3" style={{ width: "100%" }}>
                <div className="">
                    <Landlordmap source={source} />
                </div>
                <div className="flex-auto">
                    {/* <Lordlitecard source={vm.selected} /> */}
                    <Einvoicecard source={vm.selected} />
                </div>
            </div>
        </Vista>
    )
}

export function LandlordVVM() {
    ViewModel.call(this);
    this.use(LandlordmapVM).listen("SELECT");
    //this.use(LandlordcardVM).as("card");
    this.use(EinvoicecardVM).as("card");
}

core.prototypeOf(ViewModel, LandlordVVM, {
    intent: { //swipe or override
        SELECT({ data, token }) {
            token.form = this.card.form;
            this.selected = data;
            this.update();
        },
    }
});