import React from "react";
import { useWidget, Widget, core, useForm, Form, FormItem, FormVM, useValue } from "@essenza/react";
import { Input, Radio, Select, } from "antd";
import { PlaceModel } from "../../data/place";
import { Loader } from "../../component/loader";
import { NumericFormat } from 'react-number-format';
import * as yup from 'yup';

export function Einvoicecard({ source, parent }) {
	const vm = useWidget(EinvoicecardVM);

	if (source && source.company === null) source.company = false;
	if (parent) parent.vm = vm;

	const form = useForm(source, vm.schema);
	const countries = useValue("countries");
	const cities = useValue("cities");

	if (!countries) {
		const model = new PlaceModel();
		Promise.all([
			model.createSource("countries", m => m.countryList(), []),
			model.createSource("cities", m => m.cityList(), []),
		]).then(() => vm.update());
		return <Loader className="mt-12" />;
	}
	const onselect = (value, option) => {
		console.log('radio checked', value, option);
		source.$iso = value;
		source.$country = option.label;
		if(value === "IT"){
			source.$continent = value;
		}
		else if(option.areaid === 11){
			source.$continent = "UE";
		}
		else{
			source.$continent = "EXTRA_UE";
		}
		vm.update();
		//setValue(e.target.value);
	};
	if(!source) return <h1>Nessun contatto</h1>
	const onchange = (e) => {
		console.log('radio checked', e.target.value);
		source.$company = e.target.value;
		vm.update();
		//setValue(e.target.value);
	};
	return (<Widget>
		<Form form={form} layout="vertical" className="flex flex-col gap-3">
			{/* <div className="flex">
				<FormItem className="flex-auto" label="Tipo Destinatario" name="company">
					<Radio.Group onChange={onchange} >
						<Radio value={false}>Privato</Radio>
						<Radio value={true}>Azienda</Radio>
					</Radio.Group>
				</FormItem>
				<FormItem className="flex-1" label="Importo prenotazione" name="airbnb" trigger="onValueChange" getValueFromEvent={v => v.floatValue} >
					<NumericFormat className="pl-7" customInput={Input} prefix="€" decimalSeparator="," thousandSeparator="." fixedDecimalScale />
				</FormItem> 
			</div> */}
			<FormItem className="flex-auto" label="Tipo Destinatario" name="company">
				<Radio.Group onChange={onchange} >
					<Radio value={false}>Privato</Radio>
					<Radio value={true}>Azienda</Radio>
				</Radio.Group>
			</FormItem>
			{
				source.company
					? <div className="flex gap-2">
						<FormItem className="flex-auto" label="Nome azienda" name="businessname">
							<Input></Input>
						</FormItem>
						<FormItem label="Partita iva" name="vatnumber">
							<Input></Input>
						</FormItem>
					</div>
					: <div className="flex gap-2">
						<FormItem className="flex-1" label="Nome" name="firstname">
							<Input></Input>
						</FormItem>
						<FormItem className="flex-1" label="Cognome" name="lastname">
							<Input></Input>
						</FormItem>
						<FormItem className="flex-1" label="Email" name="email">
							<Input>
							</Input>
						</FormItem>
					</div>
			}
			<div className="flex gap-2">
				<FormItem className="basis-1/5" label="Codice fiscale" name="cf">
					<Input></Input>
				</FormItem>
				<FormItem className="basis-1/5" label="Codice destinatario" name="sdi">
					<Input>
					</Input>
				</FormItem>
				<FormItem className="basis-3/5" label="Pec" name="pec">
					<Input>
					</Input>
				</FormItem>
			</div>
			<FormItem label="Indirizzo" name="address">
				<Input></Input>
			</FormItem>
			<div className="flex gap-2">
				<FormItem className="w-64" label="Paese" name="iso">
					<Select onSelect={onselect} className="h-11" options={countries} variant="filled" />
				</FormItem>
				<FormItem label="Codice postale" name="postcode">
					<Input></Input>
				</FormItem>
				<FormItem className="flex-auto" label="Citta" name="city">
					<Input></Input>
				</FormItem>
				{
					source?.iso === "IT" &&
					<FormItem className="w-24" label="Provincia" name="area">
						<Select className="h-11" options={cities} variant="filled" />
					</FormItem>
				}
			</div>
		</Form>
	</Widget>
	)
}

export function EinvoicecardVM() {
	FormVM.call(this);
	this.schema = {
		rules: {
			default: yup.object({
				email: yup.string().email("Formato email non corretto").nullable(),
				pec: yup.string().email("Formato email non corretto").nullable(),
				cf: yup.string().nullable().when('iso', {
					is: 'IT',
					then: yup.string().required("Richiesto *").nullable(),
					otherwise: yup.string().nullable(),
				}),
				firstname: yup.string().required("Richiesto *").nullable(),
				lastname: yup.string().required("Richiesto *").nullable(),
				vatnumber: yup.string().required("Richiesto *").nullable(),
				businessname: yup.string().required("Richiesto *").nullable(),
				postcode: yup.string().when('iso', {
					is: 'IT',
					then: yup.string().required("Richiesto *").nullable().length(5, "Richieste 5 cifre"),
					otherwise: yup.string().required("Richiesto *").nullable(),
				}),
				city: yup.string().required("Richiesto *").nullable(),
				area: yup.string().required("Richiesto *").nullable(),
				iso: yup.string().required("Richiesto *").nullable(),
				address: yup.string().required("Richiesto *").nullable(),
			})
		}
	}
}

core.prototypeOf(FormVM, EinvoicecardVM, {

});


export function Einvitemcard({ source }) {
	const vm = useWidget(EinvoicecardVM);
	const form = useForm(source, { rules: vm.rules });

	return (<Widget>
		<Form form={form} layout="vertical" className="flex flex-col gap-3" autosave>
			<FormItem label="Descizione" name="address">
				<Input></Input>
			</FormItem>
		</Form>
	</Widget>
	)
}


export function Einvoicelite({ source, parent }) {
	const vm = useWidget(EinvoicecardVM);

	if (parent) parent.vm = vm;

	const form = useForm(source, vm.schema);

	return (<Widget>
		<Form form={form} layout="vertical" className="flex flex-col gap-3">
			<FormItem label="Descrizione commissione" name="commission_title">
				<Input></Input>
			</FormItem>
			<FormItem label="Descrizione Pulizie" name="cleaning_title">
				<Input>
				</Input>
			</FormItem>
			<FormItem label="Descrizione Biancheria" name="linen_title">
				<Input>
				</Input>
			</FormItem>
			<FormItem label="Importo lordo soggiorno" name="gross_amount" trigger="onValueChange" getValueFromEvent={v => v.floatValue}>
				<NumericFormat customInput={Input} prefix="€" decimalSeparator="," thousandSeparator="." decimalScale={2} />
			</FormItem>
		</Form>
	</Widget>
	)
}