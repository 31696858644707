import { useWidget, Widget, core, ViewModel, useModel } from "@essenza/react";
import { IT } from "../core/utils";
import { FaCheckCircle } from "react-icons/fa";
import { ReportModel } from "../data/report";
export function Home() {
    const vm = useWidget(HomeVM);
    const [report, data] = useModel(ReportModel);

    report.request(m => {
        m.all();
    }, [vm]);

    if(!data) return null;

    data.edate = new Date(); 
    data.processed = 34; 
    data.completed = 34; 
    data.critical = 0; 
    data.emitted = 34;
    //const data = { issue: 1, confirm: 3, pending: 0, problem: 0, edate: new Date(), processed: 4, completed: 4, critical: 0, emitted: 0 };
    return (
        <Widget>
            <div className="flex h-full">
                <div className="flex-1 flex flex-col">
                    <h1 className="font-bold text-lg">Monitoraggio Fatturazione</h1>
                    <div className="flex flex-wrap gap-4 mt-3 font-semibold">
                        <div className="bg-white flex-1 min-w-36 h-24 p-4 rounded-xl">
                            <h1>Da Risolvere</h1>
                            <div className="flex items-baseline">
                                <h1 className="text-5xl font-bold flex-auto">{data["3"] || 0}</h1>
                            </div>
                        </div>
                        <div className="bg-white flex-1 min-w-36 h-24 p-4 rounded-xl">
                            <h1>Da Approvare</h1>
                            <div className="flex items-baseline">
                                <h1 className="text-5xl font-bold flex-auto">{data["4"] || 0}</h1>
                            </div>
                        </div>
                        <div className="bg-white flex-1 min-w-36 h-24 p-4 rounded-xl">
                            <h1>Sospese</h1>
                            <div className="flex items-baseline">
                                <h1 className="text-5xl font-bold flex-auto">{data["6"] || 0}</h1>
                            </div>
                        </div>
                        <div className="bg-white flex-1 min-w-36 h-24 p-4 rounded-xl">
                            <h1>Attesa Saldo</h1>
                            <div className="flex items-baseline">
                                <h1 className="text-5xl font-bold flex-auto">{0}</h1>
                                {/* <BsArrowRightCircleFill className="text-2xl" /> */}
                            </div>
                        </div>
                        <div className="bg-white flex-1 min-w-36 h-24 p-4 rounded-xl">
                            <h1 className="font-bold">No label</h1>
                            <div className="flex items-baseline">
                                <h1 className="text-5xl font-bold flex-auto">{0}</h1>
                            </div>
                        </div>
                    </div>
                    <div className="flex-auto mt-4  bg-white rounded-xl">
                        <h1 className=" text-center text-2xl font-bold">STATISTICHE</h1>
                    </div>
                </div>
                <div className="flex-1 flex flex-col pl-4">
                    <h1 className="font-bold text-lg">Report Ultima Elaborazione</h1>
                    <div className="bg-white rounded-xl mt-3 p-3 flex-auto">
                        <div className="bg-dark text-white rounded-xl p-6 flex items-center">
                            <div className="flex-auto font-semobold">
                                <h1>Data Elaborazione</h1>
                                <h1>{IT.date(data.edate)}</h1>
                            </div>
                            <FaCheckCircle className="text-3xl text-cyan-400" />
                        </div>
                        <div className="flex gap-2 mt-2">
                            <div className="flex-none font-semibold">
                                <div className="bg-gray-100 w-24 h-24 p-4 rounded-xl">
                                    <h1>Elaborate</h1>
                                    <h1 className="text-2xl font-bold">{data.processed}</h1>
                                </div>
                                <div className="bg-gray-100 w-24 h-24 p-4 rounded-xl mt-2">
                                    <h1>Completate</h1>
                                    <h1 className="text-2xl font-bold">{data.completed}</h1>
                                </div>
                                <div className="bg-gray-100 w-24 h-24 p-4 rounded-xl mt-2">
                                    <h1>Anomalie</h1>
                                    <h1 className="text-2xl font-bold">{data.critical}</h1>
                                </div>
                                <div className="bg-gray-100 w-24 h-24 p-4 rounded-xl mt-2">
                                    <h1>Emesse</h1>
                                    <h1 className="text-2xl font-bold">{data.emitted}</h1>
                                </div>
                            </div>
                            <div className="flex-auto pl-4 font-semibold">
                                <h1 className="font-bold text-lg">Nessuna Anomalia Attiva</h1>
                                {/* <div className="flex gap-3 bg-gray-100 w-full mt-2 py-2 px-4 rounded-xl">
                                    <TbAlertCircleFilled className="text-2xl text-[#FAC710]" />
                                    <h1 className="flex-auto">Elaborazione prenotazioni con importi errati</h1>
                                    <h1>IN GESTIONE</h1>
                                </div> */}
                                {/* <div className="flex gap-3 bg-gray-100 mt-2 w-full py-2 px-4 rounded-xl">
                                    <TbAlertCircleFilled className="text-2xl text-red-700 " />
                                    <div className="flex-auto">Sincronizzazione dati non riuscita</div>
                                    <h1>CRITICA</h1>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Widget>
    )
}

export function HomeVM() {
    ViewModel.call(this);
}

core.prototypeOf(ViewModel, HomeVM, {
    intent: { //swipe or override
        GOTO_ADMIN: ({ context }) => {
            context.navigate("/");
        },
    }
});